import React from "react";
import { styled, keyframes } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const PartnersWrapper = styled(Box)(({ theme }) => ({
  background: 'white',
  padding: theme.spacing(8, 0),
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '1px',
    background: 'linear-gradient(90deg, transparent, rgba(59, 130, 246, 0.2), transparent)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '1px',
    background: 'linear-gradient(90deg, transparent, rgba(59, 130, 246, 0.2), transparent)',
  }
}));

const PartnerImage = styled('img')(({ theme }) => ({
  width: '150px',
  height: '100px',
  objectFit: 'contain',
  opacity: 0.7,
  transition: 'all 0.4s ease',
  margin: '0 auto',
  display: 'block',
  '&:hover': {
    filter: 'grayscale(0%)',
    opacity: 1,
    transform: 'scale(1.05)',
  }
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '120px',
  padding: theme.spacing(2),
  perspective: '1000px',
}));

const CarouselWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& .react-multi-carousel-track': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .react-multi-carousel-item': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '120px',
  },
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100px',
    zIndex: 2,
    pointerEvents: 'none'
  },
  '&::before': {
    left: 0,
    background: 'linear-gradient(to right, #ffffff, transparent)',
  },
  '&::after': {
    right: 0,
    background: 'linear-gradient(to left, #ffffff, transparent)',
  }
}));

const TitleSection = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(6),
  animation: `${fadeIn} 1s ease-out`,
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -16,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '3px',
    background: 'linear-gradient(90deg, #3b82f6, #60a5fa)',
    borderRadius: '2px'
  }
}));

const images = Array.from({ length: 23 }, (_, i) => {
  try {
    return require(`../../assets/partner/${i + 1}.png`);
  } catch (error) {
    console.warn(`Image not found: ${i + 1}.png`);
    return null;
  }
}).filter((img) => img !== null);

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const PartnersNITS = () => {
  return (
    <PartnersWrapper>
      <Container maxWidth="xl">
        <TitleSection>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: '#1e40af',
              mb: 1,
              fontSize: { xs: '1.75rem', md: '2rem' }
            }}
          >
            Our Trusted Partners
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#64748b',
              maxWidth: '600px',
              margin: '0 auto',
              fontSize: '1.1rem'
            }}
          >
            Collaborating with industry leaders to deliver excellence
          </Typography>
        </TitleSection>

        <CarouselWrapper>
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition="all 1s linear"
            transitionDuration={1000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            slidesToSlide={1}
            centerMode={false}
            partialVisible={false}
          >
            {images.map((img, index) => (
              <LogoContainer key={index}>
                <PartnerImage
                  src={img}
                  alt={`Partner ${index + 1}`}
                  loading="lazy"
                />
              </LogoContainer>
            ))}
          </Carousel>
        </CarouselWrapper>
      </Container>

      <style jsx global>{`
        .react-multi-carousel-track {
          gap: 20px;
        }
        
        .carousel-container {
          padding: 20px 0;
        }

        .carousel-item-padding-40-px {
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
        }

        @keyframes slide {
          from { transform: translateX(0); }
          to { transform: translateX(-100%); }
        }
      `}</style>
    </PartnersWrapper>
  );
};

export default PartnersNITS;
