import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import AllCategoriesBanner from "../../Components/All Categories Components/All Categories Banner/AllCategoriesBanner";
import AllCategoriesSingleGrid from "../../Components/All Categories Components/All Categories Single Grid/AllCategoriesSingleGrid";
import AllServices from "../../Components/All Services Components/All Services Data/AllServicesData";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const TitleSection = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(6),
  animation: `${fadeIn} 1s ease-out`,
  position: 'relative',
  padding: "48px 0 20px",
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -16,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '3px',
    background: 'linear-gradient(90deg, #3b82f6, #60a5fa)',
    borderRadius: '2px'
  }
}));

const SalesForceAutomation = () => {
  const bannerPara =
    "Take your sales operations to the next level with cutting-edge automation solutions from National IT Services. Our Sales Force Automation software streamlines lead management, optimizes workflows, and powers productivity - driving explosive growth. Choose from top platforms like Freshworks CRM, Freshworks Sales Suite, WhatsApp Business API, and advanced ChatBots. With powerful integrations, easy-to-use interfaces, and real-time analytics, our sales automation tools connect and empower your team like never before. The future of sales is here - embrace it and watch your revenue skyrocket with National IT Services.";
  const bannerHeading = "Supercharge Your Sales Team with Automation Software";
  const Services = AllServices.filter(service => service.category === "Cloud");

  return (
    <>
      <AllCategoriesBanner 
        bannerPara={bannerPara} 
        bannerHeading={bannerHeading} 
      />
      <Container>
        <TitleSection>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: '#1e40af',
              mb: 1,
              fontSize: { xs: '1.75rem', md: '2rem' }
            }}
          >
            Cloud and Data Centre Services
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#64748b',
              maxWidth: '600px',
              margin: '0 auto',
              fontSize: '1.1rem'
            }}
          >
            Discover a wide range of services tailored to meet your needs.
          </Typography>
        </TitleSection>
        <AllCategoriesSingleGrid Services={Services} />
      </Container>
    </>
  );
};

export default SalesForceAutomation;
