import React, { useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import OfferBanner from "../Components/Offers Components/Offers Banner/OfferBanner";
import OfferForm from "../Components/Offers Components/Offers Form/OfferForm";
import StaticBanner from "../Components/Offers Components/Static Banner/StaticBanner";
import SpecialInfo from "../Components/SpecialInfo/SpecialInfo";
import PopularBrands from "../Components/Offers Components/PopularBrands/PopularBrands";

const StaticBannerWrapper = styled(Box)({
  width: '100%',
});

const Offers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <OfferBanner />
          </Grid>
          <Grid item xs={12} lg={3}>
            <OfferForm />
          </Grid>
        </Grid>
      </Container>
      
      <StaticBannerWrapper>
        <StaticBanner />
      </StaticBannerWrapper>
      
      <PopularBrands />
      <SpecialInfo />
    </>
  );
};

export default Offers;
