import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  Breadcrumbs,
  Rating,
  Divider,
  Button,
  useTheme,
  useMediaQuery,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import {
  Group,
  Phone,
  NavigateNext,
  Support,
  CheckCircle,
  Speed,
  Verified,
  Settings,
  Storage,
  Rocket,
  MonetizationOn,
  ExpandMore,
} from "@mui/icons-material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { motion } from "framer-motion";
import AllServices from "../Components/All Services Components/All Services Data/AllServicesData";
import ProductFormPopUp from "../Components/ProductFormPopUp/ProductFormPopUp";
const ProductImage = styled(motion.img)(({ theme }) => ({
  width: "100%",
  height: { xs: "180px", sm: "220px", md: "280px", lg: "320px" },
  objectFit: "contain",
  borderRadius: { xs: "12px", md: "16px" },
  transition: "all 0.4s ease-in-out",
  padding: theme.spacing(2),
  background: "linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)",
  [theme.breakpoints.down("sm")]: {
    height: "180px",
    margin: "0 auto",
    maxWidth: "80%",
    padding: theme.spacing(1.5),
  },
  "&:hover": {
    transform: { xs: "none", md: "scale(1.02)" },
    boxShadow: "0 15px 30px rgba(0,0,0,0.06)",
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "auto",
  height: { xs: "auto", md: "60vh" },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1.5),
  borderRadius: { xs: "16px", md: "20px" },
  background: "linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)",
  boxShadow: "0 8px 20px rgba(0,0,0,0.05)",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    margin: "0 8px",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "16px",
  padding: "16px 32px",
  fontSize: "1.1rem",
  fontWeight: 600,
  textTransform: "none",
  background: "linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)",
  boxShadow: "0 8px 20px rgba(59, 130, 246, 0.25)",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: "0 12px 28px rgba(59, 130, 246, 0.35)",
    background: "linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%)",
  },
}));

const GlassCard = styled(Box)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.95)",
  backdropFilter: "blur(20px)",
  borderRadius: "24px",
  padding: theme.spacing(4),
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
}));

const FeatureBadge = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: "8px",
  padding: "8px 16px",
  borderRadius: "12px",
  background: "rgba(59, 130, 246, 0.1)",
  color: theme.palette.primary.main,
  fontWeight: 600,
  fontSize: "0.875rem",
  transition: "all 0.3s ease",
  "&:hover": {
    background: "rgba(59, 130, 246, 0.15)",
    transform: "translateY(-2px)",
  },
}));

const BenefitCard = styled(Card)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  borderRadius: "20px",
  background: "linear-gradient(135deg, #ffffff 0%, #f8fafc 100%)",
  boxShadow: "none",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4),
    width: "100%",
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  width: "60px",
  height: "60px",
  borderRadius: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
  background: "linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)",
  transition: "transform 0.3s ease",
}));

const ResponsiveContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(6),
  },
}));

// Add the fadeIn animation
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

// Add TitleSection styled component
const TitleSection = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(6),
  animation: `${fadeIn} 1s ease-out`,
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: -16,
    left: "50%",
    transform: "translateX(-50%)",
    width: "60px",
    height: "3px",
    background: "linear-gradient(90deg, #3b82f6, #60a5fa)",
    borderRadius: "2px",
  },
}));

const SingleServices = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [service, setService] = useState(null);

  useEffect(() => {
    const foundService = AllServices.find(
      (service) => service.id === parseInt(id)
    );
    setService(foundService);
    window.scrollTo(0, 0);
  }, [id]);

  if (!service) return null;

  const handleGetDemoClick = (product) => {
    setSelectedProduct(product);
  };

  const handleClose = () => {
    setSelectedProduct(null);
  };

  return (
    <ResponsiveContainer maxWidth="xl">
      <Box
        sx={{
          px: { xs: 2, sm: 3, md: 4 },
          py: { xs: 3, sm: 4, md: 6 },
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          {/* Breadcrumbs */}
          <Breadcrumbs
            separator={
              <NavigateNext fontSize="small" sx={{ color: "primary.main" }} />
            }
            sx={{
              mb: { xs: 2, md: 4 },
              pt: { xs: "50px", md: 0 },
              "& a": {
                textDecoration: "none",
                transition: "color 0.2s ease",
                "&:hover": {
                  color: "primary.main",
                },
              },
            }}
          >
            <Link
              to="/all-services"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Typography
                color="text.secondary"
                sx={{
                  fontSize: { xs: "0.875rem", sm: "0.9rem", md: "1rem" },
                }}
              >
                All Services
              </Typography>
            </Link>
            <Link
              to={
                service?.category === "Business"
                  ? "/business-applications"
                  : service?.category === "Cyber"
                  ? "/cyber-security-solutions"
                  : service?.category === "Collaboration"
                  ? "/collaboration-solutions"
                  : service?.category === "Voice"
                  ? "/voice-data-solution"
                  : service?.category === "Cloud"
                  ? "/cloud-and-data-centre-solutions"
                  : service?.category === "HR"
                  ? "/hr-software"
                  : "/all-services"
              }
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Typography
                color="text.secondary"
                sx={{
                  fontSize: { xs: "0.875rem", sm: "0.9rem", md: "1rem" },
                }}
              >
                {service?.category || "Category"}
              </Typography>
            </Link>
            <Typography
              color="primary"
              sx={{
                fontSize: { xs: "0.875rem", sm: "0.9rem", md: "1rem" },
              }}
            >
              {service?.productName || "Product"}
            </Typography>
          </Breadcrumbs>

          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            sx={{ mb: { xs: 3, md: 6 } }}
          >
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                order: { xs: 2, md: 1 },
              }}
            >
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4 }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: "1.75rem", sm: "2.25rem", md: "3rem" },
                    lineHeight: { xs: 1.2, md: 1.3 },
                    background:
                      "linear-gradient(135deg, #1e293b 0%, #334155 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    mb: { xs: 1.5, md: 2 },
                  }}
                >
                  {service.productName}
                </Typography>

                {/* Rating Section */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: { xs: 1.5, md: 3 },
                    mb: { xs: 2, md: 4 },
                    flexWrap: "wrap",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Rating
                      value={service.rating}
                      readOnly
                      precision={0.5}
                      size={isMobile ? "small" : "medium"}
                    />
                    <Typography
                      variant={isMobile ? "body2" : "body1"}
                      color="text.secondary"
                    >
                      ({service.rating})
                    </Typography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ display: { xs: "none", sm: "block" } }}
                  />
                  <FeatureBadge>
                    <Verified sx={{ fontSize: isMobile ? 16 : 20 }} />
                    Verified Solution
                  </FeatureBadge>
                </Box>

                {/* Implementation Benefits - Moved to middle on mobile */}
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mb: { xs: 3, md: 4 },
                    order: { xs: 3, md: 2 },
                    width: "100%",
                    mx: "auto",
                  }}
                >
                  {[
                    {
                      icon: <Speed />,
                      title: "Fast Implementation",
                      desc: "2-4 weeks",
                    },
                    {
                      icon: <Support />,
                      title: "24/7 Support",
                      desc: "Always available",
                    },
                    {
                      icon: <MonetizationOn />,
                      title: "Cost Effective",
                      desc: "High ROI",
                    },
                  ].map((benefit, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      key={index}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        px: { xs: 2, md: 2 },
                      }}
                    >
                      <BenefitCard>
                        <IconContainer
                          className="icon-container"
                          sx={{
                            width: { xs: "50px", sm: "60px", md: "70px" },
                            height: { xs: "50px", sm: "60px", md: "70px" },
                            mb: { xs: 1.5, md: 2 },
                          }}
                        >
                          {React.cloneElement(benefit.icon, {
                            sx: {
                              color: "white",
                              fontSize: { xs: 24, sm: 28, md: 32 },
                            },
                          })}
                        </IconContainer>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: {
                              xs: "1rem",
                              sm: "1.1rem",
                              md: "1.25rem",
                            },
                            fontWeight: 600,
                            mb: 1,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          {benefit.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          sx={{
                            fontSize: {
                              xs: "0.875rem",
                              sm: "0.9rem",
                              md: "1rem",
                            },
                            opacity: 0.8,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          {benefit.desc}
                        </Typography>
                      </BenefitCard>
                    </Grid>
                  ))}
                </Grid>

                {/* CTA Buttons */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    flexDirection: { xs: "column", sm: "row" },
                    width: "100%",
                    px: { xs: 2, md: 0 },
                  }}
                >
                  <StyledButton
                    variant="contained"
                    fullWidth
                    startIcon={<Rocket />}
                    onClick={() => handleGetDemoClick(service)}
                    sx={{
                      fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
                      padding: { xs: "12px", sm: "14px", md: "16px" },
                      borderRadius: "12px",
                      textTransform: "none",
                    }}
                  >
                    Get Started Now
                  </StyledButton>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<Phone />}
                    sx={{
                      fontSize: { xs: "1rem", md: "1.1rem" },
                      padding: "16px",
                      borderRadius: "12px",
                      borderWidth: 2,
                      textTransform: "none",
                      "&:hover": { borderWidth: 2 },
                    }}
                  >
                    Schedule Demo
                  </Button>
                </Box>
              </motion.div>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                order: { xs: 1, md: 2 },
                mb: { xs: 2, md: 0 },
              }}
            >
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 }}
              >
                <ImageContainer>
                  <ProductImage
                    src={service.imageSrc}
                    alt={service.productName}
                    loading="lazy"
                  />
                </ImageContainer>

                <Grid
                  container
                  spacing={1.5}
                  sx={{
                    mt: { xs: 1.5, md: 2 },
                    px: { xs: 1, md: 0 },
                  }}
                >
                  <Grid item xs={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 1.5,
                        borderRadius: "12px",
                        background: "rgba(255,255,255,0.8)",
                        backdropFilter: "blur(10px)",
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        border: "1px solid rgba(0,0,0,0.05)",
                      }}
                    >
                      <Verified
                        sx={{ color: "primary.main", fontSize: "1.2rem" }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 600,
                          fontSize: { xs: "0.7rem", sm: "0.8rem" },
                        }}
                      >
                        Verified Solution
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 1.5,
                        borderRadius: "12px",
                        background: "rgba(255,255,255,0.8)",
                        backdropFilter: "blur(10px)",
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        border: "1px solid rgba(0,0,0,0.05)",
                      }}
                    >
                      <Speed
                        sx={{ color: "primary.main", fontSize: "1.2rem" }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 600,
                          fontSize: { xs: "0.7rem", sm: "0.8rem" },
                        }}
                      >
                        Quick Setup
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </motion.div>
            </Grid>
          </Grid>

          {/* Features Section */}
          <GlassCard sx={{ mb: 8 }}>
            <TitleSection>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 700,
                  color: "#1e40af",
                  mb: 1,
                  fontSize: { xs: "1.75rem", md: "2rem" },
                }}
              >
                Key Features
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#64748b",
                  maxWidth: "600px",
                  margin: "0 auto",
                  fontSize: "1.1rem",
                }}
              >
                Explore the powerful features of our solution
              </Typography>
            </TitleSection>
            <Grid container spacing={3}>
              {service.description
                .split(". ")
                .filter(Boolean)
                .map((feature, index) => (
                  <Grid item xs={12} key={index}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 2,
                          p: 3,
                          borderRadius: "16px",
                          background: "rgba(255, 255, 255, 0.8)",
                          backdropFilter: "blur(10px)",
                          boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
                          transition: "all 0.3s ease",
                          "&:hover": {
                            transform: "translateY(-2px)",
                            boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                            background: "rgba(59, 130, 246, 0.04)",
                          },
                        }}
                      >
                        <CheckCircle
                          sx={{
                            color: "success.main",
                            fontSize: 24,
                            mt: 0.5,
                          }}
                        />
                        <Typography
                          variant="body1"
                          sx={{
                            color: "text.primary",
                            fontSize: { xs: "0.9rem", sm: "1rem" },
                            lineHeight: 1.6,
                          }}
                        >
                          {feature.trim()}
                        </Typography>
                      </Box>
                    </motion.div>
                  </Grid>
                ))}
            </Grid>
          </GlassCard>

          {/* Implementation Timeline */}
          <GlassCard sx={{ mb: 8 }}>
            <TitleSection>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 700,
                  color: "#1e40af",
                  mb: 1,
                  fontSize: { xs: "1.75rem", md: "2rem" },
                }}
              >
                Implementation Process
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#64748b",
                  maxWidth: "600px",
                  margin: "0 auto",
                  fontSize: "1.1rem",
                }}
              >
                Simple and efficient deployment steps
              </Typography>
            </TitleSection>
            <Timeline position="alternate">
              {[
                {
                  title: "Initial Setup",
                  icon: <Settings />,
                  description: "Quick system configuration",
                },
                {
                  title: "Data Migration",
                  icon: <Storage />,
                  description: "Seamless data transfer",
                },
                {
                  title: "Team Training",
                  icon: <Group />,
                  description: "Comprehensive training sessions",
                },
                {
                  title: "Go Live",
                  icon: <Rocket />,
                  description: "System deployment",
                },
              ].map((step, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot sx={{ bgcolor: "primary.main" }}>
                      {step.icon}
                    </TimelineDot>
                    {index < 3 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: { xs: "1rem", sm: "1.1rem", md: "1.25rem" },
                        fontWeight: 600,
                        mb: 0.5,
                      }}
                    >
                      {step.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontSize: { xs: "0.875rem", sm: "0.9rem", md: "1rem" },
                        opacity: 0.8,
                      }}
                    >
                      {step.description}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </GlassCard>

          {/* FAQ Section */}
          <GlassCard>
            <TitleSection>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 700,
                  color: "#1e40af",
                  mb: 1,
                  fontSize: { xs: "1.75rem", md: "2rem" },
                }}
              >
                Frequently Asked Questions
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#64748b",
                  maxWidth: "600px",
                  margin: "0 auto",
                  fontSize: "1.1rem",
                }}
              >
                Get answers to common questions
              </Typography>
            </TitleSection>
            {[
              {
                q: "How long does implementation take?",
                a: "Typically 2-4 weeks depending on requirements.",
              },
              {
                q: "Is training included?",
                a: "Yes, comprehensive training is included in the package.",
              },
              {
                q: "What kind of support do you provide?",
                a: "24/7 technical support via phone, email, and chat.",
              },
            ].map((faq, index) => (
              <Accordion
                key={index}
                elevation={0}
                sx={{
                  background: "transparent",
                  "&:before": { display: "none" },
                  mb: 1,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: "1rem", sm: "1.1rem", md: "1.25rem" },
                      fontWeight: 600,
                    }}
                  >
                    {faq.q}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{
                      fontSize: { xs: "0.875rem", sm: "0.9rem", md: "1rem" },
                      opacity: 0.8,
                    }}
                  >
                    {faq.a}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </GlassCard>
        </motion.div>

        {/* Form Popup */}
        <ProductFormPopUp product={selectedProduct} onClose={handleClose} />
      </Box>
    </ResponsiveContainer>
  );
};

export default SingleServices;
