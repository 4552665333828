import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import AllServicesBanner from "../Components/All Services Components/All Services Banner/AllServicesBanner";
import ServicesSingleGrid from "../Components/All Services Components/ServicesSingleGrid/ServicesSingleGrid";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const TitleSection = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(6),
  animation: `${fadeIn} 1s ease-out`,
  position: 'relative',
  padding: "48px 0 20px",
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -16,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '3px',
    background: 'linear-gradient(90deg, #3b82f6, #60a5fa)',
    borderRadius: '2px'
  }
}));

const AllServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AllServicesBanner />
      <Container>
        <TitleSection>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: '#1e40af',
              mb: 1,
              fontSize: { xs: '1.75rem', md: '2rem' }
            }}
          >
            All Services
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#64748b',
              maxWidth: '600px',
              margin: '0 auto',
              fontSize: '1.1rem'
            }}
          >
            Discover a wide range of services tailored to meet your needs.
          </Typography>
        </TitleSection>
        <ServicesSingleGrid />
      </Container>
    </>
  );
};

export default AllServices;
