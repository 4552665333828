import React, { useState } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./PopularBrands.css";
import popularBrandsData from "./Popular";
import OffersPopUp from "../OffersPopUp/OffersPopUp";

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 600,
  color: '#4285F4',
  marginBottom: '30px',
  marginTop: '30px',
  lineHeight: 1.2,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.75rem',
  }
}));

const CategoryTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: '#4285F4',
  marginBottom: '30px',
  lineHeight: 1.2,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
  }
}));

const PopularBrands = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    "Business Applications"
  );
  const filteredData = popularBrandsData.filter(
    (item) => item.category === selectedCategory
  );
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleGetDemoClick = (product) => {
    setSelectedProduct(product);
  };
  const handleClose = () => {
    setSelectedProduct(null);
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <SectionTitle>Popular Brands Offer</SectionTitle>
            </div>
          </div>
        </div>
      </div>
      <div className="container outerMainSinglePop">
        <div className="row">
          <div className="col-lg-3">
            <div className="brandInnerTopDic">
              <div className="brandImageInnerDiv">
                <img
                  src="https://i.pinimg.com/736x/4b/13/93/4b139392f980a8b346f90f686e2c0e91.jpg"
                  alt=""
                />
              </div>
              <div className="brandContentInnerDiv">
                <h6 className="brandContentInnerH3">GOTO Resolve</h6>
                <span className="hr_span"></span>
                <p className="brandContentInnerP">Upto 10% off</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="brandInnerTopDic">
              <div className="brandImageInnerDiv">
                <img
                  src="https://bsmedia.business-standard.com/_media/bs/img/article/2024-02/19/full/20240219171029.jpg"
                  alt=""
                />
              </div>
              <div className="brandContentInnerDiv">
                <h6 className="brandContentInnerH3">greytHR</h6>
                <span className="hr_span"></span>
                <p className="brandContentInnerP">Upto 10% off</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="brandInnerTopDic">
              <div className="brandImageInnerDiv">
                <img
                  src="https://img.freepik.com/premium-vector/zoom-meeting-logo_628407-1899.jpg"
                  alt="zoom"
                />
              </div>
              <div className="brandContentInnerDiv">
                <h6 className="brandContentInnerH3">
                  Zoom Meeting and Webinar
                </h6>
                <span className="hr_span"></span>
                <p className="brandContentInnerP">Upto 5% off</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="brandInnerTopDic">
              <div className="brandImageInnerDiv">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcJY9Nmlo8j0vUKXMIARq4sQ21Gd1DXz1E1w&s"
                  alt="Freshwork"
                />
              </div>
              <div className="brandContentInnerDiv">
                <h6 className="brandContentInnerH3">Freshwork CRM</h6>
                <span className="hr_span"></span>
                <p className="brandContentInnerP">Upto 10% off</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <CategoryTitle>Top Offers by Category</CategoryTitle>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="tabs-container" style={{marginBottom:'60px'}}>
              <div
                className={
                  selectedCategory === "Business Applications"
                    ? "active-tab-item"
                    : "tab-item"
                }
                onClick={() => setSelectedCategory("Business Applications")}
              >
                <i className="fa-solid fa-briefcase"></i> &nbsp; Business
                Applications
              </div>
              <div
                className={
                  selectedCategory === "Collaboration Solutions"
                    ? "active-tab-item"
                    : "tab-item"
                }
                onClick={() => setSelectedCategory("Collaboration Solutions")}
              >
                <i className="fa-solid fa-handshake"></i> &nbsp; Collaboration
                Solutions
              </div>
              <div
                className={
                  selectedCategory === "Cyber Security Solutions"
                    ? "active-tab-item"
                    : "tab-item"
                }
                onClick={() => setSelectedCategory("Cyber Security Solutions")}
              >
                <i className="fa-solid fa-shield"></i> &nbsp; Cyber Security
                Solutions
              </div>
              <div
                className={
                  selectedCategory === "Sales Force Automation"
                    ? "active-tab-item"
                    : "tab-item"
                }
                onClick={() => setSelectedCategory("Sales Force Automation")}
              >
                <i className="fa-brands fa-adversal"></i> &nbsp; Sales Force
                Automation
              </div>
              <div
                className={
                  selectedCategory === "Voice & Data Solutions"
                    ? "active-tab-item"
                    : "tab-item"
                }
                onClick={() => setSelectedCategory("Voice & Data Solutions")}
              >
                <i className="fa-solid fa-voicemail"></i> &nbsp; Voice Data
                Solutions
              </div>
            </div>
          </div>

          <div className="col-lg-9">
            <div className="row">
              {filteredData.map((item) => (
                <div className="col-lg-4" key={item.id}>
                  <div className="offerCardOuterMain">
                    <div className="topSectionPopOff">
                      <div className="offerNg">
                        <h6>{item.discount}</h6>
                      </div>
                      <div className="LogoofferPopularByCat">
                        <img src={item.imageSrc} alt="" />
                      </div>
                    </div>
                    <div className="OfferTextContentCategory">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                    <div className="OfferPriceContentCategory">
                      <h3>{item.price}</h3>
                      <h4>
                        <del>{item.originalPrice}</del>
                      </h4>
                      <br />
                      <h6>Exclusive of all taxes</h6>
                      <div className="OfferPriceContentCategoryButtonDiv">
                        <button onClick={() => handleGetDemoClick(item)}>
                          Get Offer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <OffersPopUp product={selectedProduct} onClose={handleClose} />
    </>
  );
};

export default PopularBrands;
