import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PercentIcon from '@mui/icons-material/Percent';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const BannerWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: '#ffffff',
  position: 'relative',
}));

const ContentCard = styled(Box)(({ theme }) => ({
  background: 'white',
  borderRadius: '24px',
  padding: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
  boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
  position: 'relative',
  animation: `${fadeIn} 1s ease-out`,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    textAlign: 'center',
    padding: theme.spacing(3),
  }
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: '120px',
  height: '120px',
  background: '#FF5252',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 8px 16px rgba(255, 82, 82, 0.2)',
  [theme.breakpoints.down('md')]: {
    width: '100px',
    height: '100px',
  }
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}));

const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  }
}));

const TitleText = styled(Typography)(({ theme }) => ({
  color: '#4285F4',
  fontSize: '2rem',
  fontWeight: 600,
  lineHeight: 1.2,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.75rem',
  }
}));

const Description = styled(Typography)(({ theme }) => ({
  color: '#5f6368',
  fontSize: '1.1rem',
  lineHeight: 1.4,
  marginBottom: theme.spacing(2),
}));

const ViewButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #1a237e, #1976d2)',
  color: 'white',
  padding: '10px 20px',
  borderRadius: '12px',
  fontSize: '14px',
  fontWeight: 600,
  textTransform: 'none',
  position: 'relative',
  overflow: 'hidden',
  border: 'none',
  transition: 'all 0.3s ease',
  alignSelf: 'flex-start',
  cursor: 'pointer',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(45deg, #1976d2, #1a237e)',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    zIndex: 1,
  },

  '& .MuiButton-endIcon': {
    position: 'relative',
    zIndex: 2,
  },

  '& .MuiButton-label': {
    position: 'relative',
    zIndex: 2,
  },

  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 24px rgba(25, 118, 210, 0.3)',
    backgroundColor: 'transparent',
    textDecoration: 'none',
    color: 'white',
  },

  [theme.breakpoints.down('md')]: {
    alignSelf: 'center',
  }
}));

const StaticBanner = () => {
  return (
    <BannerWrapper>
      <Container>
        <ContentCard>
          <IconWrapper>
            <PercentIcon sx={{ fontSize: 60, color: 'white' }} />
          </IconWrapper>
          <ContentWrapper>
            <Title>
              <LocalOfferIcon sx={{ color: '#4285F4', fontSize: 28 }} />
              <TitleText>
                NITS Software Deals & Discounts
              </TitleText>
            </Title>
            <Description>
              Ready to speed up your business growth? We know exactly what you need!
              Buy business software at special discounts only at National IT Services. Avail
              the best software deals from top vendors at stunning prices.
            </Description>
            <ViewButton 
              endIcon={<ArrowForwardIcon />}
              href="/offers"
            >
              VIEW ALL OFFERS
            </ViewButton>
          </ContentWrapper>
        </ContentCard>
      </Container>
    </BannerWrapper>
  );
};

export default StaticBanner;
