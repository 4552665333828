const AllServices = [
  {
    id: 1,
    productName: "Zoom Meeting and Webinar",
    productCompany: "Zoom",
    description:
      "Professional video conferencing platform offering enterprise-grade collaboration features. Key capabilities include: Extended 30-hour meeting duration for comprehensive sessions and events, integrated Mail and Calendar Client & Service for seamless scheduling and communication, Clips Plus with unlimited video recording and sharing capabilities for enhanced knowledge retention. Includes Docs Unlimited for real-time document collaboration, 5GB secure Cloud Storage for file management, and cutting-edge AI Companion for smart meeting assistance, transcription, and summarization. Essential Apps package included with premium features unlocked for the first year at no additional cost. Advanced features include breakout rooms, live streaming, custom layouts, meeting analytics, and enterprise-grade security with end-to-end encryption. Supports up to 1000 video participants, 49 videos on screen, and unlimited cloud recording storage. Perfect for businesses requiring reliable, feature-rich virtual collaboration tools for remote teams, webinars, and large-scale virtual events.",
    price: 13200,
    actualPrise: 15000,
    mrp: 18000,
    rating: 4.5,
    category: "Collaboration",
    isNewFeature: true,
    imageSrc:
      "https://img.freepik.com/premium-vector/zoom-icon-popular-messenger-app-communications-platform_277909-457.jpg?semt=ais_hybrid",
  },
  {
    id: 2,
    productName: "SAP B1 and SAP S4 HANA",
    productCompany: "SAP",
    description:
      "Comprehensive enterprise resource planning (ERP) solution powered by industry leaders Yotta/NTT. This integrated business management system combines SAP Business One and SAP S/4HANA capabilities to deliver powerful enterprise functionality. Implementation services include full system setup, data migration, and customization to match your business processes. Extensive training programs ensure smooth user adoption across all departments. Annual Maintenance Contract (AMC) provides ongoing support, updates, and system optimization. Flexible hosting options available through secure cloud infrastructure or on-premises deployment, with disaster recovery (DR) services ensuring business continuity. Includes SAP Ariba integration for streamlined procurement and supplier collaboration, Success Factor for advanced HR management and employee experience, and comprehensive Module Implementation across Finance, Sales, Purchase, Inventory, Production, and Analytics. Advanced features include real-time analytics powered by HANA in-memory computing, intelligent automation capabilities, and mobile accessibility. Suitable for both SMEs and large enterprises looking to digitally transform their operations with industry-leading ERP technology.",
    price: 50000,
    actualPrise: 60000,
    mrp: 75000,
    rating: 4.8,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZL65DQOJMS3GMp0KtbQbToNDxMkClhwfEAA&s",
  },
  {
    id: 3,
    productName: "GreytHR HRMS",
    productCompany: "GreytHR",
    description:
      "State-of-the-art Human Resource Management System (HRMS) delivering end-to-end HR process automation. Core HR features include comprehensive employee database management, document management, and organization structure visualization. Advanced Payroll system handles complex salary structures, statutory compliance, tax planning, and multi-location processing with automated salary disbursement. Sophisticated Leave Management system offers customizable leave policies, holiday calendar management, and leave accounting. Employee Self Onboarding module streamlines new hire documentation with digital forms and automated workflows. Workflow Process Management enables custom approval chains and paperless processing across HR functions. Attendance Management includes multiple marking options (biometric/mobile/web), shift management, and overtime calculations. Feature-rich Employee Portal provides self-service capabilities for leave applications, payslips, tax declarations, and company policy access. Geomark+ ensures accurate attendance tracking with location verification and real-time monitoring. Performance Management System facilitates goal setting, continuous feedback, performance reviews, and career development planning. Additional features include advanced analytics dashboard, mobile app access, and integration capabilities with other business systems. Ideal for organizations seeking to modernize their HR operations with a cloud-based, scalable solution.",
    price: 360,
    actualPrise: 400,
    mrp: 500,
    rating: 4.2,
    category: "Business",
    isNewFeature: false,
    imageSrc:
      "https://pbs.twimg.com/profile_images/1758132523354423296/W9G-4Ane_400x400.jpg",
  },
  {
    id: 4,
    productName: "Freshworks CRM",
    productCompany: "Freshworks",
    description:
      "Advanced CRM solution with AI-powered contact scoring and lead prioritization. Built-in multi-channel communication including Email, Chat, and Phone integration. Comprehensive Product Catalogue management with custom fields and categories. Native Mobile App for iOS and Android with offline capabilities. Automated Sales Sequence builder for consistent follow-ups and engagement. Multi-level Account Hierarchy visualization for complex business relationships. Real-time Customer reporting with customizable dashboards and KPIs. Advanced workflow automation for sales processes and task management. Seamless integration with popular business tools and platforms. Enterprise-grade security with role-based access control. Built-in email marketing tools with performance analytics. Complete activity tracking and customer interaction history.",
    price: 749,
    actualPrise: 900,
    mrp: 1000,
    rating: 4.3,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Freshworks-vector-logo.svg/1200px-Freshworks-vector-logo.svg.png",
  },
  {
    id: 5,
    productName: "Zoho Workplace",
    productCompany: "Zoho",
    description:
      "Comprehensive office suite offering 30GB Mail Box with 100GB Team Drive. Features multi-domain email hosting, advanced email and folder sharing capabilities. Includes a full suite of applications: Writer, Sheet, Show, and Meetings for complete office management. Empowered by Zia Gen AI for smarter collaboration and productivity enhancements. Supports easy data migration from Microsoft 365 and Google Workspace. Enhanced security features for safe and secure data handling. Customizable user permissions for team management. Integration with external apps and services for a seamless workflow.",
    price: 99,
    actualPrise: 120,
    mrp: 150,
    rating: 4.4,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://i.pinimg.com/736x/36/76/4c/36764cad429d97090de6e08a7ef82c7b.jpg",
  },
  {
    id: 6,
    productName: "DocuSign",
    productCompany: "DocuSign",
    description:
      "Leading E-Signature solution offering Intelligent Agreement Management and Contract Lifecycle Management. Features robust API integration for seamless connectivity with various platforms. Advanced Document Generation capabilities for creating and managing documents efficiently. Secure and legally binding electronic signature collection for all types of agreements. Real-time tracking and notifications for document status updates. Customizable templates for quick document creation. Integration with major platforms like Salesforce, Google, and Microsoft. Supports multiple languages and global compliance standards for international business operations.",
    price: 1000,
    actualPrise: 1200,
    mrp: 1500,
    rating: 4.6,
    category: "Business",
    isNewFeature: false,
    imageSrc:
      "https://storageprdv2inwink.blob.core.windows.net/1a2965b3-2132-40f9-983f-3f92a35743fe/0e8c1e10-6adc-4ffa-a782-4098981d8aa9",
  },
  {
    id: 7,
    productName: "Smart Flow",
    productCompany: "Tata Tele Smartflo",
    description:
      "Comprehensive contact center solution offering unlimited calling and a robust softphone system. Features CRM integration for seamless customer data management. Advanced call recording and analytics for quality assurance and training purposes. Agent features include call barge-in and whispering for effective call handling. Supports multi-channel communication including voice, video, and chat. Customizable IVR for efficient call routing. Real-time monitoring and reporting tools for performance tracking. Scalable architecture to accommodate business growth.",
    price: 500,
    actualPrise: 600,
    mrp: 750,
    rating: 4.1,
    category: "Voice",
    isNewFeature: true,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQah1Lx2979lH8mJO8BR_mpO7xjglbcQntYzQ&s",
  },
  {
    id: 8,
    productName: "Meeting Room System",
    productCompany: "Multiple Vendors: Jabra, Cisco, Poly, Yealink",
    description:
      "Professional meeting room setups tailored for all sizes from personal to large room configurations. Certified devices compatible with Teams, Zoom, and Webex platforms. Features include a 180-degree Meeting Bar and Beam forming microphone for optimal audio clarity. High-definition video capabilities for a superior meeting experience. Easy integration with existing IT infrastructure. Customizable setups to meet specific organizational needs. Advanced security features to ensure safe and private communications. Energy-efficient designs for sustainable operation.",
    price: 25000,
    actualPrise: 30000,
    mrp: 35000,
    rating: 4.7,
    category: "Collaboration",
    isNewFeature: true,
    imageSrc:
      "https://www.dsppatech.com/uploads/image/20180628/17/necessity-of-a-meeting-room-speaker-system.jpg",
  },

  // Category: Network Services
  {
    id: 9,
    productName: "Internet Leased Line",
    productCompany: "Multiple Providers :- Tata/ Ishan/ NTT",
    description:
      "High-speed dedicated internet service offering guaranteed bandwidth and uptime. Features include Smart Internet with managed and unmanaged services, special ISP pricing, and 24/7 customer support. Ideal for businesses requiring stable and reliable internet connectivity. Scalable bandwidth options to meet growing business needs. Enhanced security with dedicated lines reducing the risk of data breaches. SLA-backed services ensuring high service standards. Direct peering with major content delivery networks for improved performance. Flexible contract terms to suit business requirements.",
    price: 10000,
    actualPrise: 12000,
    mrp: 15000,
    rating: 4.6,
    category: "Voice",
    isNewFeature: false,
    imageSrc: "https://4.imimg.com/data4/FB/EJ/GLADMIN-24669842/1-500x500.png",
  },

  {
    id: 10,
    productName: "Smart VPN",
    productCompany: "Tata",
    description:
      "Secure VPN service with managed router support and comprehensive network management. Offers both domestic and global connectivity options. Features class of service management for prioritizing network traffic. Ensures secure and private connections for remote access. Scalable to accommodate varying business sizes and needs. Easy integration with existing IT infrastructure. 24/7 monitoring and support to ensure continuous service. Enhanced data encryption standards to protect sensitive information.",
    price: 20000,
    actualPrise: 25000,
    mrp: 30000,
    rating: 4.4,
    category: "Voice",
    isNewFeature: true,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQah1Lx2979lH8mJO8BR_mpO7xjglbcQntYzQ&s",
  },
  {
    id: 11,
    productName: "Managed WiFi",
    productCompany: "Multiple Vendors :- Engenious/ Cisco/ Aruba",
    description:
      "Advanced managed WiFi services using cutting-edge technology from Engenious, Cisco, and Aruba. Supports the latest WiFi 7 standard for high-speed internet access. Features hosted and on-premises controller options. Provides OTP-based guest access for secure, temporary internet access. Bundled internet solutions for streamlined setup and management. Scalable solutions suitable for businesses of all sizes. Comprehensive coverage options ensuring reliable connectivity across premises. 24/7 monitoring and support for uninterrupted service.",
    price: 15000,
    actualPrise: 18000,
    mrp: 22000,
    rating: 4.5,
    category: "Voice",
    isNewFeature: true,
    imageSrc:
      "https://www.dojonetworks.com/hubfs/Videos/Dojo_wifi_02.mp4/medium.jpg",
  },
  {
    id: 12,
    productName: "Network Monitoring & Management",
    productCompany: "Motadata",
    description:
      "Comprehensive network monitoring and management solution from Motadata. Features include Network Observability, Infrastructure Monitoring, and Log Analytics. Network Automation tools streamline network operations. Integrated Service Desk and Asset Manager for efficient IT management. Real-time alerts and notifications for immediate issue resolution. Customizable dashboards for a tailored monitoring experience. Supports a wide range of network devices and interfaces. Scalable solution to grow with your business needs.",
    price: 30000,
    actualPrise: 35000,
    mrp: 40000,
    rating: 4.3,
    category: "Voice",
    isNewFeature: false,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQamuxFVPXgI2plGfnlsaV5y8TWK7arT4QuBg&s",
  },
  {
    id: 13,
    productName: "SDWAN and SASE",
    productCompany: "Multiple Vendors :- Versa/ Fortinet/ Cisco/ Silverpeak",
    description:
      "Next-generation networking solutions combining SDWAN and SASE from top providers like Versa, Fortinet, Cisco, and Silverpeak. Offers Basic, ATP, and UTM options for comprehensive security. Managed services ensure expert setup and ongoing management. Secure access controls protect against unauthorized entry. Real-time monitoring dashboard for instant network insights. Flexible Capex and Opex financial options to suit budget requirements. Scalable architecture supports business growth and expansion. Enhanced network performance and reliability for critical business applications.",
    price: 25000,
    actualPrise: 30000,
    mrp: 35000,
    rating: 4.7,
    category: "Voice",
    isNewFeature: true,
    imageSrc:
      "https://firebearstudio.com/blog/wp-content/uploads/2017/08/chrome_2018-09-11_19-16-22.png",
  },
  {
    id: 14,
    productName: "Content Delivery Network CDN",
    productCompany: "Multiple Providers :- Akamai/ Cloud Flaire",
    description:
      "Robust CDN solutions from industry leaders Akamai and Cloud Flare. Ensures secure and efficient content distribution across global networks. Ideal for live streaming, OTT services, and website acceleration. Offers integration with major cloud platforms like AWS, Azure, and Bitgravity. Advanced caching mechanisms reduce load times and improve user experience. Customizable security features to protect against DDoS attacks and other threats. Scalable solutions to handle peak traffic loads. 24/7 support and real-time analytics for optimal performance.",
    price: 40000,
    actualPrise: 45000,
    mrp: 50000,
    rating: 4.8,
    category: "Voice",
    isNewFeature: true,
    imageSrc:
      "https://theincmagazine.com/wp-content/uploads/2023/04/CONTENT-DELIVERY-NETWORK-Exploring-the-Power-01-1.jpg",
  },
  {
    id: 15,
    productName: "Network/Firewall Devices",
    productCompany: "Multiple Vendors :- Cisco/ Juniper/ Checkpoint",
    description:
      "Comprehensive network and security devices from top vendors including Cisco, Juniper, and Checkpoint. Features device licenses, installation, and monitoring services. Fortinet and HP Aruba options also available. Provides robust protection against network threats. Scalable solutions to support network expansion. Easy integration with existing IT infrastructure. 24/7 technical support for uninterrupted service. Advanced security features ensure data integrity and confidentiality.",
    price: 35000,
    actualPrise: 40000,
    mrp: 45000,
    rating: 4.6,
    category: "Voice",
    isNewFeature: false,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY9FjKr_87LyXXWWZ-LJj3JAKJjzmdXBvtZQ&s",
  },

  // Category: Cyber Security
  {
    id: 17,
    productName: "Data Leakage Prevention - DLP",
    productCompany: "Safetica, Cososys, Trellix, Escan",
    description:
      "Advanced data protection solution designed to prevent data breaches and unauthorized data transmission. Features profile-wise sharing rights to control data access. Includes data templates for consistent data handling. Supports multiple operating systems for versatile deployment. Available in both on-premises and SaaS models to fit business needs. Endpoint and mail DLP to secure data across devices and communication channels. Real-time monitoring and alerts for immediate incident response. Comprehensive reporting tools for audit and compliance purposes.",
    price: 12000,
    actualPrise: 14000,
    mrp: 16000,
    rating: 4.5,
    category: "Cyber",
    isNewFeature: false,
    imageSrc:
      "https://cdn.prod.website-files.com/5ff66329429d880392f6cba2/60757c372a2abacb1d83d9aa_what%20is%20dlp.jpeg",
  },
  {
    id: 18,
    productName: "VAPT/GAP Assessment",
    productCompany: "Security Services",
    description:
      "Comprehensive vulnerability assessment and penetration testing services. Offers both Grey and Black Box Testing for thorough security checks. Includes hardware and software assessments to identify vulnerabilities. Optional source code testing for application security verification. IP-based testing for devices, websites, and applications. Provides detailed mitigation support to address identified vulnerabilities. Customizable testing scopes to focus on specific organizational needs. Continuous monitoring and updates for ongoing security assurance.",
    price: 20000,
    actualPrise: 25000,
    mrp: 30000,
    rating: 4.4,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxHMHkfEUi9Gw9msQvCzqTvEtpWjQDWIuihA&s",
  },
  {
    id: 19,
    productName: "SIEM/SOAR",
    productCompany: "Security Operations",
    description:
      "Integrated Security Information and Event Management (SIEM) and Security Orchestration, Automation, and Response (SOAR) solutions. Provides 24x7 Security Operations Center (SOC) support. Features on-premises and cloud-based log collectors for flexible data aggregation. Ensures compliance with global security standards. Offers tiered support levels (L1, L2, L3) for tailored security operations. Global SOC centers for worldwide threat monitoring and response. Advanced analytics for real-time threat detection and response. Automation capabilities to streamline incident response processes.",
    price: 30000,
    actualPrise: 35000,
    mrp: 40000,
    rating: 4.8,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://cdn.prod.website-files.com/62d5d3d58a8916f99109ac72/664cfc1041a944ab83fe1b19_Pillr-by-OpenText-Logo-Normal-OTBLUEColor_360px%5B94%5D.png",
  },
  {
    id: 20,
    productName: "MDM",
    productCompany: "GoTo Miradore, Jamf, Scalefusion, eScan",
    description:
      "Comprehensive Mobile Device Management (MDM) solutions for multi-OS environments. Offers robust application control and scripting capabilities. Features asset tracking for device inventory management. Includes service tickets for efficient issue resolution. Provides remote support for device troubleshooting. Patch management to ensure devices are up-to-date with the latest security patches. Flexible deployment options including GoTo Resolve, Jamf, and Scalefusion. User-friendly interface for easy management and monitoring.",
    price: 18000,
    actualPrice: 22000,
    mrp: 25000,
    rating: 4.3,
    category: "Cyber",
    imageSrc: "https://logowik.com/content/uploads/images/goto-new6911.jpg",
  },
  {
    id: 21,
    productName: "ITSM",
    productCompany: "GoTo, Motadata, Freshdesk",
    description:
      "Integrated IT Service Management (ITSM) solutions for comprehensive device management. Supports multi-OS environments for versatile application. Features application control and scripting for automated operations. Asset tracking for complete visibility of IT assets. Service tickets for streamlined issue resolution processes. Remote support capabilities for efficient troubleshooting. Patch management to maintain security and functionality. Options include GoTo Resolve, Jamf, and Scalefusion for tailored ITSM strategies.",
    price: 18000,
    actualPrice: 22000,
    mrp: 25000,
    rating: 4.3,
    category: "Cyber",
    imageSrc: "https://logowik.com/content/uploads/images/goto-new6911.jpg",
  },
  {
    id: 22,
    productName: "Endpoint Security with XDR",
    productCompany: "Multiple Vendors",
    description:
      "Comprehensive endpoint security solutions from Trendmicro, Kaspersky, E-Set, Escan, Trellix with Email Security add-on option. Features advanced threat detection and response (XDR) capabilities. Provides real-time monitoring and automated response to threats. Supports a wide range of devices including mobile and IoT. Integrates with existing security infrastructure for enhanced protection. Offers user behavior analytics to detect anomalies. Cloud-based management for easy scalability. Includes comprehensive reporting for audit and compliance. Ensures data protection with encryption and anti-malware tools.",
    price: 16000,
    actualPrise: 19000,
    mrp: 22000,
    rating: 4.6,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1l-_Qpw3024trqVl_m-5PrzohpPAstRJb6w&s",
  },
  {
    id: 23,
    productName: "GOTO Resolve - Remote Support & Access",
    productCompany: "Goto",
    description:
      "Online platform for video conferencing and webinars with tools for business expansion, automatic invitations, follow-up emails, and lead generation tactics. Trusted by 50K users globally. Offers high-definition video and audio quality for clear communication. Features screen sharing and remote control for effective presentations and support. Integrates with popular business tools for seamless workflow. Provides secure connections with end-to-end encryption. Allows recording and playback of sessions for training and review. Supports mobile devices for access on the go. Customizable branding options to align with company identity.",
    price: 200,
    actualPrise: 300,
    mrp: 500,
    rating: 4,
    category: "Collaboration",
    isNewFeature: true,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRY01eQjk76KdcAelhk5sYoXabc8YvlIhGpoA&s",
  },
  {
    id: 24,
    productName: "TeamViewer",
    productCompany: "TeamViewer",
    description:
      "Remote access software for desktop sharing and file transfer. Secure screen sharing, file sharing, unattended access, and VPN alternative. Supports Windows, Mac, iOS, and Android. Enables remote control of devices for IT support and personal use. Offers high-speed connections for seamless user experience. Features cross-platform access to connect different operating systems. Provides robust security measures including AES encryption. Allows session recording for compliance and training purposes. Supports multi-user sessions for collaborative work. Customizable user permissions for controlled access.",
    price: 200,
    actualPrise: 300,
    mrp: 500,
    rating: 4,
    category: "Collaboration",
    isNewFeature: true,
    imageSrc:
      "https://play-lh.googleusercontent.com/kiuamHjSKvVwS2fZ9c3dgPBPLDNQhExfw_8_vWhTdFMIQp1Lnf5gl5ZPbO2R55BzBk4",
  },
  {
    id: 25,
    productName: "Google Workspace",
    productCompany: "Google",
    description:
      "Cloud-based software suite offering collaboration tools like Gmail, Docs, Sheets, Forms, Drive, and more. Includes centralized admin console and endpoint management for enterprises. Facilitates seamless collaboration with real-time editing and commenting. Offers extensive integration with third-party apps. Provides advanced security features including two-factor authentication. Includes AI-powered features for smart suggestions and automation. Offers extensive storage options with Google Drive. Supports compliance with GDPR and other regulatory standards.",
    price: 200,
    actualPrise: 300,
    mrp: 500,
    rating: 4.6,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://www.techbrein.com/data/2023/04/google-workspace-banner-2.png",
  },
  {
    id: 26,
    productName: "Colocation Services",
    productCompany: "NTT Global, Yotta, STT",
    description:
      "Reliable colocation services with global connectivity. Ensure high availability, scalability, and security for your IT infrastructure. Offers flexible space options from racks to cages to suites. Provides robust physical security including biometric access and 24/7 surveillance. Features high redundancy for power and cooling systems. Supports high network uptime with multiple connectivity options. Allows for rapid scalability to meet growing business needs. Includes hands-on support and remote hands services. Compliant with major industry standards for data center operations.",
    price: 50000,
    actualPrise: 60000,
    mrp: 75000,
    rating: 4.7,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://www.datafoundry.com/wp-content/uploads/2024/02/df_fortune_5_colocation-1.png",
  },
  {
    id: 27,
    productName: "Cloud Services",
    productCompany:
      "AWS, Azure, Tata IZO Cloud, NTT Simpli Cloud, Shakti Cloud",
    description:
      "Comprehensive cloud solutions for businesses. Scalable computing power, storage, and advanced cloud features to meet enterprise needs. Offers a wide range of services including IaaS, PaaS, and SaaS. Provides global data center presence for reduced latency. Features advanced security measures to protect data. Supports hybrid cloud environments for flexibility. Includes tools for big data analytics and machine learning. Offers customizable solutions to fit specific business requirements. Provides 24/7 support and management services.",
    price: 10000,
    actualPrise: 12000,
    mrp: 15000,
    rating: 4.8,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://www.device42.com/blog/wp-content/uploads/2023/03/14_01edge-cloud-computing-900x600.jpg",
  },
  {
    id: 28,
    productName: "GPU as a Service",
    productCompany: "AI & ML Applications",
    description:
      "High-performance GPU computing for AI and ML applications. Accelerate workloads with scalable GPU cloud solutions. Provides access to top-tier GPUs for intensive computational tasks. Supports a wide range of AI frameworks and libraries. Offers flexible pricing models based on usage. Ensures high availability and reliability for critical applications. Features easy integration with existing AI workflows. Provides comprehensive monitoring and management tools. Supports multi-tenant environments for cost efficiency.",
    price: 30000,
    actualPrise: 35000,
    mrp: 40000,
    rating: 4.6,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://yotta.com/wp-content/uploads/2023/09/gpu-as-a-service-revolution-transforming-hpc-and-virtual-pro-workstations-1.jpg",
  },
  {
    id: 29,
    productName: "Data Backup",
    productCompany: "Druva, Carbonite, Yotta Safe",
    description:
      "Secure and scalable data backup solutions to protect your critical data. Cloud-based, automated backups with quick recovery options. Offers end-to-end encryption for data security. Supports incremental and full backups for efficiency. Provides disaster recovery options to ensure business continuity. Features centralized management for ease of use. Supports multiple platforms and devices. Includes compliance features for regulatory requirements. Offers 24/7 customer support and proactive monitoring.",
    price: 5000,
    actualPrise: 6000,
    mrp: 7500,
    rating: 4.7,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://www.advanced-pcs.com/wp-content/uploads/2023/04/Data-Backup2-scaled.jpeg",
  },
  {
    id: 30,
    productName: "VDI/Desktop as a Service",
    productCompany: "Accops",
    description:
      "Virtual Desktop Infrastructure (VDI) solutions for remote and hybrid workforces. Secure, high-performance desktop as a service. Offers centralized management of desktop environments. Provides scalable solutions to accommodate business growth. Ensures high availability and disaster recovery capabilities. Supports multiple operating systems and devices. Integrates with existing IT infrastructure seamlessly. Features robust security measures including multi-factor authentication and end-to-end encryption. Delivers a consistent user experience across all devices. Reduces IT overhead with streamlined deployment and maintenance.",
    price: 15000,
    actualPrise: 18000,
    mrp: 22000,
    rating: 4.5,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://www.ironorbit.com/wp-content/uploads/2024/06/AdobeStock_612227503-scaled.jpeg",
  },
  {
    id: 31,
    productName: "Cloud Storage",
    productCompany: "Zoho WorkDrive, AWS",
    description:
      "Flexible and secure cloud storage solutions for personal and enterprise use. Store, share, and collaborate on files with ease. Offers scalable storage options to meet growing data needs. Features robust data encryption for security. Supports file versioning for easy recovery of previous versions. Integrates seamlessly with other cloud services for enhanced functionality. Provides access controls to manage user permissions. Includes automated backup systems for data protection. Offers global accessibility for remote teams.",
    price: 500,
    actualPrise: 600,
    mrp: 800,
    rating: 4.4,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://www.hkcert.org/f/guideline/218189/1200c630/hkcert-Cloud%20Storage%20Security%20banner-1860x1046.jpg",
  },
  {
    id: 32,
    productName: "Chatbot",
    productCompany: "Freshchat",
    description:
      "AI-powered chatbot to enhance customer engagement and streamline support. Real-time responses with natural language processing. Customizable to fit specific business needs. Integrates with major CRM and ERP systems. Supports multiple languages for global reach. Provides analytics to track engagement and effectiveness. Capable of handling thousands of conversations simultaneously. Offers self-service options to reduce support workload. Ensures 24/7 availability for customer inquiries.",
    price: 1200,
    actualPrise: 1500,
    mrp: 1800,
    rating: 4.5,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://img.freepik.com/free-vector/chatbot-chat-message-vectorart_78370-4104.jpg",
  },
  {
    id: 33,
    productName: "Bulk Email",
    productCompany: "Zepto Mail, Netcore",
    description:
      "Efficient bulk email solutions for marketing and transactional communication. Scalable, secure, and high-performance email delivery. Features advanced deliverability analytics. Supports custom email templates for branding. Includes A/B testing for campaign optimization. Provides detailed reports on opens, clicks, and conversions. Integrates with existing marketing tools. Offers compliance with anti-spam regulations. Capable of handling large volumes of emails daily.",
    price: 2000,
    actualPrise: 2500,
    mrp: 3000,
    rating: 4.5,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://www.wpoven.com/blog/wp-content/uploads/2022/09/bulk_mail_service.png",
  },
  {
    id: 34,
    productName: "Email Backup/Archiving",
    productCompany: "MailStore, Netcore",
    description:
      "Comprehensive email backup and archiving solutions to ensure data retention and compliance. Features advanced search and secure storage. Supports automated backup schedules. Provides legal compliance and e-discovery capabilities. Offers seamless integration with email platforms. Ensures data integrity with encryption. Allows for quick retrieval of archived emails. Minimizes risk of data loss. Suitable for businesses of all sizes.",
    price: 3000,
    actualPrise: 3500,
    mrp: 4000,
    rating: 4.6,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://jatheon.com/wp-content/uploads/2021/06/The-Difference-Between-Email-Archiving-and-Email-Backup-Blog.png",
  },
  {
    id: 35,
    productName: "Remote Support App",
    productCompany: "TeamViewer, Goto Resolve",
    description:
      "Remote support applications for secure desktop sharing, file transfer, and troubleshooting. Features include unattended access and cross-platform support. Provides secure and reliable connections. Offers full remote control capabilities. Supports multiple simultaneous sessions. Integrates with IT service management tools. Features session recording for audit and training. Offers file transfer and chat during sessions. Customizable for specific business requirements.",
    price: 5000,
    actualPrise: 6000,
    mrp: 7500,
    rating: 4.4,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://ossisto.com/wp-content/uploads/2024/01/Types-of-Remote-IT-Support-1.webp",
  },
  {
    id: 36,
    productName: "Asset Management",
    productCompany: "Freshdesk, Goto Resolve",
    description:
      "Comprehensive asset management solutions for tracking, managing, and optimizing IT assets. Features include lifecycle tracking and reporting. Provides real-time asset tracking and management. Supports IT asset lifecycle management. Integrates with ITSM for streamlined operations. Offers customizable reporting for asset analysis. Includes maintenance scheduling to reduce downtime. Supports barcode and RFID tagging. Provides alerts for asset maintenance and replacement.",
    price: 10000,
    actualPrise: 12000,
    mrp: 15000,
    rating: 4.6,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://happay.com/blog/wp-content/uploads/sites/12/2023/04/best-asset-management-software-system-solutions.webp",
  },
  {
    id: 37,
    productName: "PAM Solutions",
    productCompany: "BeyondTrust, Okta",
    description:
      "Privileged Access Management (PAM) solutions for secure and compliant management of privileged accounts. Includes multi-factor authentication and session monitoring. Provides granular access controls. Supports just-in-time and just-enough access policies. Integrates with existing security frameworks. Offers detailed auditing and reporting for compliance. Provides secure remote access capabilities. Features session recording and playback. Supports scalability for growing organizations.",
    price: 20000,
    actualPrise: 25000,
    mrp: 30000,
    rating: 4.7,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://www.cit-net.com/wp-content/uploads/2023/05/Blog-TL-01-1-1030x545.png",
  },
  {
    id: 38,
    productName: "EDRM",
    productCompany: "SECLORE",
    description:
      "Enterprise Digital Rights Management (EDRM) solution for securing sensitive data. Features include policy-driven access controls and tracking. Provides robust data protection across platforms. Supports automated policy application. Integrates with existing enterprise applications. Offers real-time monitoring and alerts. Features detailed access and usage reports. Supports data protection in transit and at rest. Provides scalability for enterprise needs.",
    price: 15000,
    actualPrise: 18000,
    mrp: 22000,
    rating: 4.5,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://www.stellexgroup.com/uploads/1/3/2/8/132840864/screen-shot-2021-01-19-at-6-11-46-am_orig.png",
  },
  {
    id: 39,
    productName: "SASE Solution",
    productCompany: "Zscaler, Fortinet, Versa, Checkpoint, Netskope",
    description:
      "Secure Access Service Edge (SASE) solutions for unified cloud-based security and networking. Features include zero-trust access and threat protection. Combines WAN capabilities with cloud-native security functions. Provides seamless connectivity and security to all edges. Enhances user experience with optimized routing. Offers identity-based security policy enforcement. Integrates with existing network infrastructure. Supports secure remote access for distributed workforces. Provides detailed analytics for network and security events. Ensures compliance with global security standards.",
    price: 30000,
    actualPrise: 35000,
    mrp: 40000,
    rating: 4.8,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://embed-ssl.wistia.com/deliveries/3e9387b8f03c92bd9d2a26f774e3e810.webp?image_crop_resized=1280x720",
  },
  {
    id: 40,
    productName: "Checkpoint Security",
    productCompany: "Checkpoint",
    description:
      "Comprehensive cyber security solutions offering firewall, intrusion prevention, endpoint protection, and cloud security. Delivers multi-layered security to protect against advanced threats. Features state-of-the-art intrusion prevention systems. Provides unified management across all environments. Offers robust endpoint protection with behavioral analysis. Ensures secure cloud deployments with cloud-specific security tools. Supports compliance with data protection regulations. Offers scalability to meet the needs of growing businesses.",
    price: 25000,
    actualPrise: 30000,
    mrp: 35000,
    rating: 4.7,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://cdn.crn.in/wp-content/uploads/2024/07/05103704/New-Project-1.jpg",
  },
  {
    id: 41,
    productName: "Fortinet Cyber Protection",
    productCompany: "Fortinet",
    description:
      "Advanced cybersecurity solutions with endpoint security, next-gen firewall, secure SD-WAN, and threat intelligence. Provides comprehensive protection against evolving cyber threats. Features high-performance next-gen firewalls for perimeter defense. Includes advanced endpoint security with automated threat detection and response. Offers secure SD-WAN for safe, scalable, and resilient network connectivity. Integrates threat intelligence for proactive defense strategies. Supports multi-cloud environments for flexible deployments. Provides centralized management for simplified security operations.",
    price: 30000,
    actualPrise: 35000,
    mrp: 40000,
    rating: 4.8,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://www.telecomreview.com/images/stories/2023/12/Fortinet_Enhances_Operational_Technology_Security.jpg",
  },
  {
    id: 42,
    productName: "WhatsApp Business API",
    productCompany: "Tata Teleservices",
    description:
      "Enterprise-grade WhatsApp Business API solution offering seamless customer engagement and business communication. Features include: AI-powered chatbots for 24/7 customer support, bulk messaging campaigns with high delivery rates, automated responses and workflows, deep CRM integration for personalized interactions, customizable message templates, detailed analytics dashboard for performance tracking, multi-agent support with role-based access, end-to-end encryption for secure communications, and automated appointment scheduling. Perfect for businesses looking to enhance customer service, boost engagement, and streamline communication workflows on WhatsApp's trusted platform. Enables direct communication with customers through a widely used platform. Supports interactive messaging with rich media capabilities. Allows for quick adaptation to customer feedback and market changes. Provides tools for customer segmentation and targeted messaging.",
    price: 5000,
    actualPrise: 6000,
    mrp: 7500,
    rating: 4.4,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://repository-images.githubusercontent.com/171072967/f0240000-b107-11ea-8c2e-02755c9b1505",
  },
  {
    id: 43,
    productName: "Kaspersky EDR Optimum",
    productCompany: "Kaspersky",
    description:
      "Advanced Endpoint Detection and Response (EDR) solution combining streamlined EDR with powerful endpoint protection. Features include threat visibility, guided response, IoC scanning, cloud security monitoring, and automated attack surface reduction. Includes cybersecurity training for IT staff. Provides comprehensive threat management with real-time data analysis. Supports automated response to incidents. Integrates with existing security infrastructure for enhanced protection. Offers tools for forensic analysis and threat hunting.",
    price: 501,
    actualPrise: 590,
    mrp: 750,
    rating: 4.7,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIdFSu1HkVviNpuIRoOqVlQ4WjC3riMnAWbg&s",
  },
  {
    id: 44,
    productName: "Rediffmail for Work",
    productCompany: "Rediff",
    description:
      "Enterprise email solution with data storage in India, featuring advanced collaboration tools, DMARC & ATP security, disaster recovery, archival services, and hybrid setup options. Includes RBol Pro for project management, sophisticated search features, and complete customizability for businesses. Offers robust security features including advanced threat protection and DMARC compliance. Supports seamless integration with existing IT infrastructure. Provides high availability and disaster recovery options to ensure business continuity. Offers scalable solutions to meet the needs of growing businesses.",
    price: 999,
    actualPrise: 1200,
    mrp: 1500,
    rating: 4.5,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://m.economictimes.com/thumb/msid-68680508,width-1200,height-900,resizemode-4,imgsize-44595/rediff-agencies.jpg",
  },
];

export default AllServices;
