import React from "react";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Button } from '@mui/material';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { keyframes } from '@mui/system';

// Animations
const float = keyframes`
  0% { transform: translateY(0px) }
  50% { transform: translateY(-10px) }
  100% { transform: translateY(0px) }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(255,255,255,0.4) }
  70% { box-shadow: 0 0 0 20px rgba(255,255,255,0) }
  100% { box-shadow: 0 0 0 0 rgba(255,255,255,0) }
`;

// Styled Components
const C2aWrapper = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #2196f3 0%, #64b5f6 100%)',
  position: 'relative',
  overflow: 'hidden',
  padding: theme.spacing(8, 0),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at 50% 50%, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 70%)',
    animation: `${float} 6s ease-in-out infinite`
  }
}));

const QuoteButton = styled(Button)(({ theme }) => ({
  background: 'rgba(255,255,255,0.25)',
  backdropFilter: 'blur(10px)',
  border: '2px solid rgba(255,255,255,0.4)',
  borderRadius: '30px',
  padding: '12px 32px',
  color: 'white',
  fontSize: '1.1rem',
  fontWeight: 600,
  textTransform: 'none',
  transition: 'all 0.3s ease',
  animation: `${pulse} 2s infinite`,
  '&:hover': {
    background: 'rgba(255,255,255,0.35)',
    transform: 'translateY(-4px) scale(1.02)',
    boxShadow: '0 20px 40px rgba(25, 118, 210, 0.2)',
    border: '2px solid rgba(255,255,255,0.6)',
  }
}));

// Add some sparkle animation
const sparkle = keyframes`
  0% { transform: scale(0) rotate(0deg); opacity: 0; }
  50% { transform: scale(1) rotate(180deg); opacity: 0.3; }
  100% { transform: scale(0) rotate(360deg); opacity: 0; }
`;

const C2aComponent = () => {
  return (
    <C2aWrapper>
      {/* Add decorative elements */}
      {[...Array(3)].map((_, i) => (
        <Box
          key={i}
          sx={{
            position: 'absolute',
            width: '150px',
            height: '150px',
            background: 'linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)',
            borderRadius: '50%',
            animation: `${sparkle} ${4 + i}s infinite linear`,
            top: `${20 + (i * 30)}%`,
            left: `${10 + (i * 30)}%`,
            zIndex: 0
          }}
        />
      ))}

      <Container 
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: { xs: 4, md: 8 },
          position: 'relative',
          zIndex: 1
        }}
      >
        <Typography 
          variant="h4" 
          sx={{
            color: 'white',
            fontWeight: 700,
            textAlign: { xs: 'center', md: 'left' },
            fontSize: { xs: '1.75rem', md: '2.25rem' },
            maxWidth: '600px',
            textShadow: '0 2px 4px rgba(0,0,0,0.1)',
            letterSpacing: '0.5px',
            lineHeight: 1.4
          }}
        >
          Are you happy with what we offer?
          <br />
          Ask for a Free Quote
        </Typography>

        <Box sx={{ 
          display: 'flex',
          justifyContent: 'center',
          position: 'relative'
        }}>
          <QuoteButton
            component={Link}
            to="/get-in-touch"
            startIcon={
              <RequestQuoteIcon sx={{ 
                fontSize: 24,
                filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
              }} />
            }
          >
            Ask for Quotation
          </QuoteButton>
        </Box>
      </Container>
    </C2aWrapper>
  );
};

export default C2aComponent;
