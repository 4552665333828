import React from "react";
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import { motion } from 'framer-motion';

// Import SVGs
import usp_1nits from "../../assets/special/usp_1nits.svg";
import usp_2nits from "../../assets/special/usp_2nits.svg";
import usp_3nits from "../../assets/special/usp_3nits.svg";
import usp_4nits from "../../assets/special/usp_4nits.svg";

// Enhanced Animations
const float = keyframes`
  0%, 100% { transform: translateY(0) rotate(0deg); }
  25% { transform: translateY(-10px) rotate(-2deg); }
  75% { transform: translateY(10px) rotate(2deg); }
`;

const glow = keyframes`
  0%, 100% { filter: drop-shadow(0 0 8px rgba(25, 118, 210, 0.3)); }
  50% { filter: drop-shadow(0 0 20px rgba(25, 118, 210, 0.6)); }
`;

const shimmerAnimation = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
`;

const borderAnimation = keyframes`
  0% {
    background-position: 0% 0%, 100% 0%, 100% 100%, 0% 100%;
  }
  25% {
    background-position: 100% 0%, 100% 100%, 0% 100%, 0% 0%;
  }
  50% {
    background-position: 100% 100%, 0% 100%, 0% 0%, 100% 0%;
  }
  75% {
    background-position: 0% 100%, 0% 0%, 100% 0%, 100% 100%;
  }
  100% {
    background-position: 0% 0%, 100% 0%, 100% 100%, 0% 100%;
  }
`;

const liquidAnimation = keyframes`
  0% {
    border-radius: 24px;
    background-position: 0% 0%;
  }
  25% {
    border-radius: 26px 22px 24px 24px;
  }
  50% {
    border-radius: 24px 24px 22px 26px;
    background-position: 100% 100%;
  }
  75% {
    border-radius: 22px 26px 24px 24px;
  }
  100% {
    border-radius: 24px;
    background-position: 0% 0%;
  }
`;

// Styled Components with Enhanced Effects
const StatsWrapper = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(to bottom, #ffffff, #f8fafc)',
  padding: theme.spacing(8, 2),
  position: 'relative',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    height: '2px',
    background: 'linear-gradient(90deg, transparent, rgba(59, 130, 246, 0.3), transparent)',
  },
  '&::before': {
    top: 0,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '100%',
      background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.8), transparent)',
      animation: `${shimmerAnimation} 2s infinite linear`,
    }
  },
  '&::after': {
    bottom: 0,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '100%',
      background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.8), transparent)',
      animation: `${shimmerAnimation} 2s infinite linear`,
    }
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(12, 0),
  }
}));

const StatCard = styled(motion.div)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(12px)',
  borderRadius: 24,
  padding: theme.spacing(4),
  textAlign: 'center',
  position: 'relative',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  isolation: 'isolate',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    right: '-2px',
    bottom: '-2px',
    borderRadius: 24,
    padding: '2px',
    background: `linear-gradient(
      45deg,
      rgba(59, 130, 246, 0.6) 0%,
      rgba(37, 99, 235, 0.6) 25%,
      rgba(59, 130, 246, 0.3) 50%,
      rgba(37, 99, 235, 0.6) 75%,
      rgba(59, 130, 246, 0.6) 100%
    )`,
    backgroundSize: '300% 300%',
    animation: `${liquidAnimation} 8s ease-in-out infinite`,
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    opacity: 0.3,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255, 255, 255, 0.9)',
    borderRadius: 24,
    zIndex: -1,
  },
  '&:hover': {
    transform: 'translateY(-8px)',
    '& .stat-icon': {
      transform: 'scale(1.1)',
      background: 'rgba(59, 130, 246, 0.1)',
    },
    '&::before': {
      opacity: 0.6,
      animationDuration: '4s',
      background: `linear-gradient(
        45deg,
        rgba(59, 130, 246, 0.8) 0%,
        rgba(37, 99, 235, 0.8) 25%,
        rgba(59, 130, 246, 0.4) 50%,
        rgba(37, 99, 235, 0.8) 75%,
        rgba(59, 130, 246, 0.8) 100%
      )`,
    }
  },
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 90,
  height: 90,
  margin: '0 auto 24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  background: 'rgba(59, 130, 246, 0.06)',
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: -2,
    borderRadius: '50%',
    padding: 2,
    background: 'linear-gradient(135deg, rgba(59, 130, 246, 0.2), rgba(37, 99, 235, 0.1))',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
  '& img': {
    width: '50%',
    height: '50%',
    objectFit: 'contain',
    filter: 'drop-shadow(0 4px 6px rgba(59, 130, 246, 0.1))'
  },
  [theme.breakpoints.up('sm')]: {
    width: 100,
    height: 100,
  }
}));

const stats = [
  { 
    icon: usp_1nits, 
    count: "300+", 
    label: "Softwares",
    description: "Wide range of software solutions"
  },
  { 
    icon: usp_2nits, 
    count: "Best Price", 
    label: "Guaranteed",
    description: "Competitive pricing for all services"
  },
  { 
    icon: usp_3nits, 
    count: "Free Expert", 
    label: "Consultation",
    description: "Professional guidance at no cost"
  },
  { 
    icon: usp_4nits, 
    count: "500+", 
    label: "Happy Customers",
    description: "Satisfied clients worldwide"
  }
];

const SpecialInfo = () => {
  return (
    <StatsWrapper>
      <Container maxWidth="lg">
        <Grid 
          container 
          spacing={{ xs: 3, md: 4 }}
          alignItems="stretch"
        >
          {stats.map((stat, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <StatCard
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ 
                  duration: 0.6,
                  delay: index * 0.1,
                  ease: [0.4, 0, 0.2, 1]
                }}
              >
                <IconWrapper className="stat-icon">
                  <img src={stat.icon} alt={stat.label} />
                </IconWrapper>
                <Typography
                  variant="h3"
                  sx={{
                    background: 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    fontWeight: 700,
                    fontSize: { 
                      xs: '1.75rem',
                      sm: '2rem',
                      md: '2.25rem'
                    },
                    mb: 1,
                    lineHeight: 1.2,
                    letterSpacing: '-0.02em'
                  }}
                >
                  {stat.count}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: '#334155',
                    fontWeight: 600,
                    mb: 2,
                    fontSize: { 
                      xs: '1rem',
                      sm: '1.125rem'
                    },
                    letterSpacing: '-0.01em'
                  }}
                >
                  {stat.label}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#64748b',
                    fontSize: '0.875rem',
                    lineHeight: 1.7,
                    maxWidth: '85%',
                    margin: '0 auto',
                    opacity: 0.9
                  }}
                >
                  {stat.description}
                </Typography>
              </StatCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StatsWrapper>
  );
};

export default SpecialInfo;
