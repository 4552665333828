import React from "react";
import { Box, Container, Stack, IconButton, useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const TopStrip = styled(Box)(({ theme }) => ({
  background: "linear-gradient(45deg, #1a237e, #1976d2)",
  position: 'fixed',
  width: '100%',
  top: 0,
  left: 0,
  zIndex: 10000,
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  backdropFilter: 'blur(8px)',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  [theme.breakpoints.down('sm')]: {
    height: '36px',
  }
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '48px !important',
    paddingRight: '48px !important'
  },
  [theme.breakpoints.up('xl')]: {
    paddingLeft: '64px !important',
    paddingRight: '64px !important'
  }
}));

const ContactInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  [theme.breakpoints.down('md')]: {
    gap: '12px'
  },
  [theme.breakpoints.down('sm')]: {
    gap: '8px'
  }
}));

const ContactLink = styled('a')({
  color: 'rgba(255, 255, 255, 0.95)',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '13.5px',
  fontWeight: 500,
  letterSpacing: '0.3px',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  padding: '6px 10px',
  borderRadius: '6px',
  whiteSpace: 'nowrap',
  '&:hover': {
    background: 'rgba(255,255,255,0.12)',
    transform: 'translateY(-1px)',
    color: '#ffffff'
  }
});

const SocialButton = styled(IconButton)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.9)',
  padding: '6px',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  background: 'rgba(255,255,255,0.05)',
  marginLeft: '4px',
  '&:hover': {
    background: 'rgba(255,255,255,0.15)',
    transform: 'translateY(-2px)',
  },
  '& .MuiSvgIcon-root': {
    color: 'rgba(255, 255, 255, 0.95)',
    fontSize: '18px'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '4px',
    '& .MuiSvgIcon-root': {
      fontSize: '16px'
    }
  }
}));

const InfoText = styled(Box)(({ theme }) => ({
  fontSize: '13.5px',
  fontWeight: 500,
  color: 'rgba(255, 255, 255, 0.95)',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '6px 10px',
  borderRadius: '6px',
  letterSpacing: '0.3px',
  background: 'rgba(255,255,255,0.05)',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

const StripNav = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <TopStrip>
        <StyledContainer maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
            <ContactInfo>
              <ContactLink href="tel:98119 98370" sx={{ minWidth: 'auto', padding: isSmallMobile ? '4px 8px' : '6px 10px' }}>
                <PhoneIcon sx={{ fontSize: isSmallMobile ? 16 : 17 }} />
                {!isSmallMobile && '98119 98370'}
              </ContactLink>

              {!isMobile && (
                <ContactLink href="mailto:info@itforsme.in">
                  <EmailIcon sx={{ fontSize: 17 }} />
                  info@itforsme.in
                </ContactLink>
              )}

              <InfoText>
                <LocationOnIcon sx={{ fontSize: 17 }} />
                Faridabad- HR, India
              </InfoText>
            </ContactInfo>

            <Stack direction="row" spacing={1} alignItems="center" sx={{ display: { xs: 'flex' }, gap: isSmallMobile ? '4px' : '8px' }}>
              {['instagram', 'facebook', 'linkedin', 'twitter'].map((social) => (
                <SocialButton key={social} href={`https://www.${social}.com/national_it_services`} target="_blank" rel="noopener noreferrer" size="small">
                  {social === 'instagram' && <InstagramIcon />}
                  {social === 'facebook' && <FacebookIcon />}
                  {social === 'linkedin' && <LinkedInIcon />}
                  {social === 'twitter' && <TwitterIcon />}
                </SocialButton>
              ))}
            </Stack>
          </Stack>
        </StyledContainer>
      </TopStrip>
      <Box sx={{ height: { xs: '36px', sm: '42px' } }} />
    </>
  );
};

export default StripNav;