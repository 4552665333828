import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  Stack,
  useTheme,
  useMediaQuery,
  Fade,
  Paper,
  Divider,
  Avatar,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import SecurityIcon from "@mui/icons-material/Security";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import GroupsIcon from "@mui/icons-material/Groups";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import logo from "../../assets/National-it-services-logo.png";
import pdf from "../../assets/NITS_Company_Profile.pdf";

const Navbar = () => {
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElCategories, setAnchorElCategories] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorElServices, setAnchorElServices] = useState(null);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

  const categories = [
    {
      title: "Business Applications",
      path: "/business-applications",
      icon: <BusinessCenterIcon />,
    },
    {
      title: "Voice & Data Solutions",
      path: "/voice-data-solution",
      icon: <SettingsVoiceIcon />,
    },
    {
      title: "Cyber Security Solutions",
      path: "/cyber-security-solutions",
      icon: <SecurityIcon />,
    },
    {
      title: "Collaboration Solutions",
      path: "/collaboration-solutions",
      icon: <GroupsIcon />,
    },
    {
      title: "Cloud & Data Centre Solutions",
      path: "/cloud-and-data-centre-solutions",
      icon: <CloudQueueIcon />,
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setIsServicesOpen(false);
  };
  const handleOpenCategories = (event) =>
    setAnchorElCategories(event.currentTarget);
  const handleCloseCategories = () => setAnchorElCategories(null);
  const handleOpenServices = (event) =>
    setAnchorElServices(event.currentTarget);
  const handleCloseServices = () => setAnchorElServices(null);

  const isActivePath = (path) => location.pathname === path;

  const isServicesActive = () => {
    return categories.some((category) => location.pathname === category.path);
  };

  const toggleServices = (event) => {
    event.stopPropagation();
    setIsServicesOpen(!isServicesOpen);
  };

  const toggleCategories = (event) => {
    event.stopPropagation();
    setIsCategoriesOpen(!isCategoriesOpen);
  };

  return (
    <AppBar position="fixed" sx={{ top: 40, bgcolor: "white", boxShadow: 2 }}>
      <Container 
        maxWidth="xl" 
        sx={{ 
          px: { xs: 0, md: 8 }
        }}
      >
        <Toolbar 
          disableGutters 
          sx={{ 
            justifyContent: 'space-between',
            px: { xs: 2, md: 0 }
          }}
        >
          {/* Logo */}
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              style={{
                height: isScrolled ? "35px" : "40px",
                marginRight: "20px",
                transition: "all 0.3s ease",
              }}
            />
          </Link>

          {/* Mobile Menu */}
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              sx={{
                color: "black",
                "&:hover": {
                  bgcolor: "rgba(0,0,0,0.04)",
                  transform: "scale(1.1)",
                },
                transition: "all 0.2s ease",
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              TransitionComponent={Fade}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiPaper-root": {
                  borderRadius: "16px",
                  mt: 1.5,
                  boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                  minWidth: "250px",
                },
              }}
            >
              {/* All Categories Button */}
              <MenuItem
                onClick={toggleCategories}
                sx={{
                  py: 1.5,
                  px: 2.5,
                  display: "flex",
                  justifyContent: "space-between",
                  color: isServicesActive() ? "#f8c03b" : "inherit",
                  "&:hover": {
                    bgcolor: "rgba(248,192,59,0.08)",
                  },
                }}
              >
                <Typography>All Categories</Typography>
                <KeyboardArrowDownIcon
                  sx={{
                    transform: isCategoriesOpen ? "rotate(180deg)" : "rotate(0)",
                    transition: "transform 0.3s ease",
                  }}
                />
              </MenuItem>

              {/* Categories Dropdown */}
              <Collapse in={isCategoriesOpen} timeout="auto">
                <Box
                  sx={{
                    bgcolor: "rgba(248,192,59,0.04)",
                    mx: 1,
                    borderRadius: "12px",
                  }}
                >
                  {categories.map((category) => (
                    <MenuItem
                      key={category.path}
                      component={Link}
                      to={category.path}
                      onClick={handleCloseNavMenu}
                      selected={isActivePath(category.path)}
                      sx={{
                        py: 1.5,
                        px: 2.5,
                        color: isActivePath(category.path) ? "#f8c03b" : "inherit",
                        "&.Mui-selected": {
                          bgcolor: "rgba(248,192,59,0.12)",
                        },
                        "&:hover": {
                          bgcolor: "rgba(248,192,59,0.08)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          mr: 2,
                          color: isActivePath(category.path)
                            ? "#f8c03b"
                            : "inherit",
                        }}
                      >
                        {category.icon}
                      </Box>
                      <Typography>{category.title}</Typography>
                    </MenuItem>
                  ))}
                </Box>
              </Collapse>

              {/* All Services Link */}
              <MenuItem
                component={Link}
                to="/all-services"
                onClick={handleCloseNavMenu}
                selected={isActivePath("/all-services")}
                sx={{
                  py: 1.5,
                  px: 2.5,
                  color: isActivePath("/all-services") ? "#f8c03b" : "inherit",
                  "&.Mui-selected": {
                    bgcolor: "rgba(248,192,59,0.12)",
                  },
                  "&:hover": {
                    bgcolor: "rgba(248,192,59,0.08)",
                  },
                }}
              >
                <Typography>All Services</Typography>
              </MenuItem>

              <Divider sx={{ my: 1 }} />

              {/* Other Menu Items */}
              {["/blogs", "/offers", "/get-in-touch"].map((path) => (
                <MenuItem
                  key={path}
                  component={Link}
                  to={path}
                  onClick={handleCloseNavMenu}
                  selected={isActivePath(path)}
                  sx={{
                    py: 1.5,
                    px: 2.5,
                    color: isActivePath(path) ? "#f8c03b" : "inherit",
                    "&.Mui-selected": {
                      bgcolor: "rgba(248,192,59,0.12)",
                    },
                    "&:hover": {
                      bgcolor: "rgba(248,192,59,0.08)",
                    },
                  }}
                >
                  <Typography>
                    {path
                      .slice(1)
                      .split("-")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </Typography>
                </MenuItem>
              ))}

              <Divider sx={{ my: 1 }} />

              {/* Company Profile Download Button */}
              <Box sx={{ px: 2, py: 1 }}>
                <Button
                  href={pdf}
                  download="NITS_Company_Profile"
                  variant="contained"
                  fullWidth
                  sx={{
                    py: 1.5,
                    background: "linear-gradient(135deg, #1a237e 0%, #1976d2 100%)",
                    borderRadius: "12px",
                    textTransform: "none",
                    fontSize: "15px",
                    fontWeight: 600,
                    letterSpacing: "0.5px",
                    "&:hover": {
                      background: "linear-gradient(135deg, #1976d2 0%, #1a237e 100%)",
                      transform: "translateY(-2px)",
                      boxShadow: "0 8px 24px rgba(25, 118, 210, 0.3)",
                    },
                    transition: "all 0.3s ease",
                  }}
                >
                  Company Profile
                </Button>
              </Box>
            </Menu>
          </Box>

          {/* Desktop Menu */}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}
          >
            {/* Categories Dropdown */}
            <Button
              onClick={handleOpenCategories}
              sx={{
                color: "black",
                px: 2,
                py: 1,
                borderRadius: "12px",
                "&:hover": {
                  bgcolor: "rgba(248,192,59,0.08)",
                  transform: "translateY(-2px)",
                },
                transition: "all 0.2s ease",
              }}
              endIcon={<KeyboardArrowDownIcon />}
            >
              All Categories
            </Button>
            <Menu
              anchorEl={anchorElCategories}
              open={Boolean(anchorElCategories)}
              onClose={handleCloseCategories}
              TransitionComponent={Fade}
              sx={{
                mt: "10px",
                "& .MuiPaper-root": {
                  borderRadius: "16px",
                  boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                },
              }}
            >
              <Paper elevation={0} sx={{ p: 1, minWidth: "320px" }}>
                {categories.map((category) => (
                  <MenuItem
                    key={category.path}
                    component={Link}
                    to={category.path}
                    onClick={handleCloseCategories}
                    selected={isActivePath(category.path)}
                    sx={{
                      borderRadius: "12px",
                      mb: 0.5,
                      py: 1.5,
                      color: isActivePath(category.path)
                        ? "#f8c03b"
                        : "inherit",
                      "&:hover": {
                        bgcolor: "rgba(248,192,59,0.08)",
                        "& .MuiSvgIcon-root": {
                          color: "#f8c03b",
                        },
                      },
                      "&.Mui-selected": {
                        bgcolor: "rgba(248,192,59,0.12)",
                        color: "#f8c03b",
                        "&:hover": {
                          bgcolor: "rgba(248,192,59,0.16)",
                        },
                      },
                    }}
                  >
                    <Avatar
                      sx={{
                        mr: 2,
                        bgcolor: isActivePath(category.path)
                          ? "#f8c03b"
                          : "rgba(0,0,0,0.04)",
                        width: 32,
                        height: 32,
                      }}
                    >
                      {category.icon}
                    </Avatar>
                    {category.title}
                  </MenuItem>
                ))}
              </Paper>
            </Menu>

            {/* Other Navigation Links */}
            {["All Services", "Blogs", "Offers", "Get in Touch"].map((text) => {
              const path = `/${text.toLowerCase().replace(/ /g, "-")}`;
              return (
                <Button
                  key={text}
                  component={Link}
                  to={path}
                  sx={{
                    color: isActivePath(path) ? "#f8c03b" : "black",
                    px: 2,
                    py: 1,
                    borderRadius: "12px",
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: "6px",
                      left: "50%",
                      transform: isActivePath(path)
                        ? "translateX(-50%)"
                        : "translateX(-50%) scaleX(0)",
                      height: "3px",
                      width: "20px",
                      backgroundColor: "#f8c03b",
                      borderRadius: "2px",
                      transition: "all 0.3s ease",
                    },
                    "&:hover": {
                      bgcolor: "rgba(248,192,59,0.08)",
                      transform: "translateY(-2px)",
                      "&::after": {
                        transform: "translateX(-50%) scaleX(1)",
                      },
                    },
                    transition: "all 0.2s ease",
                  }}
                >
                  {text}
                </Button>
              );
            })}

            {/* Company Profile Button */}
            <Button
              href={pdf}
              download="National-IT_Services"
              variant="contained"
              sx={{
                ml: 2,
                px: 3,
                py: 1,
                background: "linear-gradient(135deg, #1a237e 0%, #1976d2 100%)",
                borderRadius: "14px",
                textTransform: "none",
                fontSize: "15px",
                fontWeight: 600,
                letterSpacing: "0.5px",
                "&:hover": {
                  background:
                    "linear-gradient(135deg, #1976d2 0%, #1a237e 100%)",
                  transform: "translateY(-2px)",
                  boxShadow: "0 8px 24px rgba(25, 118, 210, 0.3)",
                },
                transition: "all 0.3s ease",
              }}
            >
              Company Profile
            </Button>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
