import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { 
  Button, 
  CircularProgress, 
  Snackbar, 
  Alert, 
  Stack, 
  Container, 
  Box, 
  Typography,
  TextField
} from "@mui/material";
import { motion } from 'framer-motion';
import banner from "../../assets/form-banners/banner1/banner-img.png";

const BannerWrapper = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #f8fafc 0%, #ffffff 100%)',
  padding: theme.spacing(10, 0),
  overflow: 'hidden',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    background: 'radial-gradient(circle at top right, rgba(59, 130, 246, 0.1), transparent 70%)',
    zIndex: 0
  }
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(8)
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(8px)',
    borderRadius: 12,
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(59, 130, 246, 0.2)',
      borderWidth: '1.5px'
    }
  }
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: 12,
  padding: '12px 32px',
  fontSize: '1rem',
  textTransform: 'none',
  background: 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)',
  boxShadow: '0 4px 14px rgba(59, 130, 246, 0.25)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 20px rgba(59, 130, 246, 0.35)'
  }
}));

const ImageWrapper = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: 'linear-gradient(135deg, rgba(59, 130, 246, 0.1) 0%, transparent 100%)',
    borderRadius: 24
  },
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: 24,
    filter: 'drop-shadow(0 20px 40px rgba(59, 130, 246, 0.15))'
  }
}));

const FormBanner = () => {
  const [formData, setFormData] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const handleCloseSnackBar = (event, reason) => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const apiUrl =
    "https://itforsmebackend.netlify.app/.netlify/functions/api/boost";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          setOpen(true);
          setFormData({
            email: "",
          });
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          throw new Error("API request failed");
        }
      } catch (error) {
        console.error(error);
        setOpen(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <BannerWrapper>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 6 }}>
          <ContentWrapper sx={{ flex: 1 }}>
            <Typography
              component="h2"
              variant="h3"
              sx={{
                fontWeight: 700,
                fontSize: { xs: '2rem', md: '2.5rem' },
                background: 'linear-gradient(135deg, #1e40af 0%, #3b82f6 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 3
              }}
            >
              Give your Business Real boost Now!
            </Typography>
            
            <Typography
              variant="body1"
              sx={{
                color: '#475569',
                fontSize: '1.1rem',
                lineHeight: 1.7,
                mb: 4
              }}
            >
              Discover the ideal product from our services kit and catapult your business 
              to new heights. Make informed choices that will fuel your business growth.
            </Typography>

            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <StyledTextField
                  fullWidth
                  id="email"
                  type="email"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />

                <SubmitButton
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  fullWidth
                >
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: 'white' }} />
                  ) : (
                    "Get Started"
                  )}
                </SubmitButton>
              </Stack>
            </form>
          </ContentWrapper>

          <ImageWrapper
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
            sx={{ flex: 1 }}
          >
            <img src={banner} alt="Business Growth Illustration" />
          </ImageWrapper>
        </Box>
      </Container>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="success"
          sx={{
            backgroundColor: '#22c55e',
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white'
            }
          }}
        >
          Email sent successfully
        </Alert>
      </Snackbar>
    </BannerWrapper>
  );
};

export default FormBanner;
