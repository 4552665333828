import React, { useEffect, useState } from "react";
import { 
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  IconButton,
  Typography,
  Box,
  TextField,
  Button,
  Fade,
  Divider,
  Chip,
  Avatar,
  Tooltip
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import VerifiedIcon from "@mui/icons-material/Verified";
import DiscountIcon from "@mui/icons-material/Discount";

// Styled Components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 20,
    maxWidth: 1000,
    width: '95%',
    background: '#ffffff',
    overflow: 'hidden',
    boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
    },
    '& fieldset': {
      borderWidth: '1.5px',
    },
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.95rem',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: 12,
  padding: '12px 32px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  background: 'linear-gradient(45deg, #FF6B6B 30%, #FF8E53 90%)', // Different gradient for offers
  boxShadow: '0 4px 20px rgba(255, 107, 107, 0.3)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(255, 107, 107, 0.4)',
  },
}));

const FeatureChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  padding: theme.spacing(2),
  borderRadius: 12,
  fontWeight: 600,
  fontSize: '0.95rem',
  backgroundColor: 'rgba(255, 255, 255, 0.15)',
  color: '#fff',
  border: '2px solid rgba(255, 255, 255, 0.2)',
  transition: 'all 0.3s ease',
  '& .MuiChip-icon': {
    fontSize: '1.4rem',
    color: '#fff',
  },
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    transform: 'translateY(-3px)',
    boxShadow: '0 5px 15px rgba(0,0,0,0.2)',
  },
}));

const CompanyAvatar = styled(Avatar)(({ theme }) => ({
  width: 48,
  height: 48,
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  backgroundColor: 'rgba(255,255,255,0.15)',
  border: '2px solid rgba(255,255,255,0.3)',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
    border: '2px solid rgba(255,255,255,0.5)',
  },
}));

const OffersPopUp = ({ product, onClose }) => {
  const [formData, setFormData] = useState({
    phoneNumber: "",
    name: "",
    companyEmail: "",
    productName: "",
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const companies = [
    { name: 'Featured Partner 1', logo: 'B' },
    { name: 'Featured Partner 2', logo: 'K' },
    { name: 'Featured Partner 3', logo: 'A' }
  ];

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    }
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.companyEmail) {
      newErrors.companyEmail = "Company email is required";
    }
    if (!formData.productName) {
      newErrors.productName = "Product Name is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const apiUrl = "https://itforsmebackend.netlify.app/.netlify/functions/api/sendemail";
    if (validateForm()) {
      setLoading(true);
      fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setOpen(true);
            setFormData({
              phoneNumber: "",
              name: "",
              companyEmail: "",
              productName: "",
            });
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
          return response.json();
        })
        .then((data) => {
          console.log("API response:", data);
          onClose();
        })
        .catch((error) => {
          console.error("API error:", error);
        });
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    // Set initial form data when the component mounts
    if (product) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        productName: product.title || "",
      }));
    }

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [onClose, product]);

  return (
    <>
      <StyledDialog
        open={Boolean(product)}
        onClose={onClose}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 400 }}
      >
        <Box sx={{ display: 'flex', minHeight: '600px' }}>
          {/* Left Side - Form */}
          <Box sx={{ flex: 1, p: 4 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <Typography variant="h4" fontWeight="700" color="error.main">
                Special Offer
              </Typography>
              <IconButton 
                onClick={onClose}
                sx={{ 
                  bgcolor: 'rgba(0,0,0,0.05)',
                  '&:hover': { bgcolor: 'rgba(0,0,0,0.1)' }
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography variant="body1" color="text.secondary" mb={4}>
              Fill out the form below to claim this exclusive offer!
            </Typography>

            <form onSubmit={handleSubmit}>
              <StyledTextField
                fullWidth
                id="name"
                label="Full Name"
                variant="outlined"
                value={formData.name}
                onChange={handleChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
                InputProps={{
                  startAdornment: (
                    <Box sx={{ mr: 1, color: 'error.main' }}>
                      <PersonIcon />
                    </Box>
                  ),
                }}
              />

              <StyledTextField
                fullWidth
                id="companyEmail"
                label="Company Email"
                variant="outlined"
                value={formData.companyEmail}
                onChange={handleChange}
                error={Boolean(errors.companyEmail)}
                helperText={errors.companyEmail}
                InputProps={{
                  startAdornment: (
                    <Box sx={{ mr: 1, color: 'error.main' }}>
                      <EmailIcon />
                    </Box>
                  ),
                }}
              />

              <StyledTextField
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                variant="outlined"
                value={formData.phoneNumber}
                onChange={handleChange}
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber}
                InputProps={{
                  startAdornment: (
                    <Box sx={{ mr: 1, color: 'error.main' }}>
                      <PhoneIcon />
                    </Box>
                  ),
                }}
              />

              <StyledTextField
                fullWidth
                id="productName"
                label="Product Name"
                variant="outlined"
                value={formData.productName}
                onChange={handleChange}
                error={Boolean(errors.productName)}
                helperText={errors.productName}
                InputProps={{
                  startAdornment: (
                    <Box sx={{ mr: 1, color: 'error.main' }}>
                      <ShoppingBagIcon />
                    </Box>
                  ),
                }}
              />

              <Box sx={{ mt: 4, textAlign: 'center' }}>
                <SubmitButton
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  fullWidth
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Claim Offer'
                  )}
                </SubmitButton>
              </Box>
            </form>
          </Box>

          {/* Right Side - Offer Info */}
          <Box
            sx={{
              flex: 1,
              color: 'white',
              p: 4,
              display: { xs: 'none', md: 'block' },
              background: 'linear-gradient(135deg, #FF6B6B 0%, #FF8E53 100%)',
            }}
          >
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h4" fontWeight="700" mb={3}>
                {product?.title}
              </Typography>

              <Box sx={{ mb: 4 }}>
                <Typography variant="h6" fontWeight="600" mb={2}>
                  Offer Benefits
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
                  <Tooltip title="Limited time special pricing" arrow>
                    <FeatureChip
                      icon={<LocalOfferIcon />}
                      label="Special Price"
                    />
                  </Tooltip>
                  <Tooltip title="Verified quality product" arrow>
                    <FeatureChip
                      icon={<VerifiedIcon />}
                      label="Quality Assured"
                    />
                  </Tooltip>
                  <Tooltip title="Additional discounts available" arrow>
                    <FeatureChip
                      icon={<DiscountIcon />}
                      label="Extra Savings"
                    />
                  </Tooltip>
                </Box>
              </Box>

              <Box sx={{ mt: 'auto' }}>
                <Divider sx={{ bgcolor: 'rgba(255,255,255,0.2)', mb: 3 }} />
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                  Featured Partners
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {companies.map((company, index) => (
                    <Tooltip
                      key={index}
                      title={company.name}
                      arrow
                      placement="top"
                    >
                      <CompanyAvatar>
                        {company.logo}
                      </CompanyAvatar>
                    </Tooltip>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledDialog>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="success"
          variant="filled"
          sx={{ 
            borderRadius: 2,
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          }}
        >
          Offer Claimed Successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default OffersPopUp;
