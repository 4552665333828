import React from "react";
import blogData from "./blog";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { 
  Box, 
  Container, 
  Typography, 
  Card, 
  CardMedia, 
  CardContent,
  Grid 
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BlogCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 16,
  overflow: 'hidden',
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 16px 32px rgba(0,0,0,0.1)',
    '& .blog-image': {
      transform: 'scale(1.08)',
    },
    '& .read-more-btn': {
      gap: '12px',
      color: theme.palette.primary.main,
    }
  }
}));

const DateBadge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 16,
  right: 16,
  padding: '8px 16px',
  background: 'linear-gradient(135deg, #2196f3 0%, #64b5f6 100%)',
  color: 'white',
  borderRadius: '20px',
  fontSize: '0.875rem',
  fontWeight: 600,
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255,255,255,0.2)',
}));

const SectionSubtitle = styled(Typography)({
  color: "#475569",
  fontSize: "1.1rem",
  lineHeight: 1.7,
  marginBottom: "0px",
  position: 'relative',
  paddingBottom: '0px',
  textAlign: 'center',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -16,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '3px',
    background: 'linear-gradient(90deg, #3b82f6, #60a5fa)',
    borderRadius: '2px'
  }
});

const HomePageBlog = () => {
  const sortedBlogData = blogData.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  const latestBlogs = sortedBlogData.slice(0, 3);

  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography 
            variant="h2" 
            sx={{ 
              fontWeight: 800,
              mb: 2,
              background: 'linear-gradient(135deg, #1a237e 0%, #1976d2 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontSize: { xs: '2rem', md: '2.5rem' }
            }}
          >
            Recent Blogs
          </Typography>
          <SectionSubtitle variant="body1">
            Explore our most popular enterprise solutions
          </SectionSubtitle>
        </Box>

        <Grid container spacing={4}>
          {latestBlogs.map((blog) => (
            <Grid item xs={12} md={4} key={blog.id}>
              <BlogCard>
                <Box sx={{ position: 'relative', pt: '60%', overflow: 'hidden' }}>
                  <CardMedia
                    component="img"
                    image={blog.imageSrc}
                    alt={blog.title}
                    className="blog-image"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      transition: 'transform 0.4s ease'
                    }}
                  />
                  <DateBadge>{blog.date}</DateBadge>
                </Box>

                <CardContent sx={{ flexGrow: 1, p: 3 }}>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      fontWeight: 700,
                      mb: 2,
                      minHeight: blog.title.split(' ').length > 5 ? 'auto' : '64px'
                    }}
                  >
                    {blog.title}
                  </Typography>

                  <Typography 
                    sx={{ 
                      color: 'text.secondary',
                      mb: 3,
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      lineHeight: 1.6
                    }}
                  >
                    {blog.intro.slice(0, 200)}{blog.intro.length > 200 ? "..." : ""}
                  </Typography>

                  <Link 
                    to={`/blog/${blog.id}`} 
                    style={{ textDecoration: 'none' }}
                  >
                    <Box 
                      className="read-more-btn"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: 'text.secondary',
                        fontWeight: 600,
                        transition: 'all 0.3s ease'
                      }}
                    >
                      Read More
                      <ArrowForwardIcon sx={{ fontSize: 18 }} />
                    </Box>
                  </Link>
                </CardContent>
              </BlogCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HomePageBlog;