import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import AllCategoriesBanner from "../../Components/All Categories Components/All Categories Banner/AllCategoriesBanner";
import AllCategoriesSingleGrid from "../../Components/All Categories Components/All Categories Single Grid/AllCategoriesSingleGrid";
import AllServices from "../../Components/All Services Components/All Services Data/AllServicesData";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const TitleSection = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(6),
  animation: `${fadeIn} 1s ease-out`,
  position: 'relative',
  padding: "48px 0 20px",
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -16,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '3px',
    background: 'linear-gradient(90deg, #3b82f6, #60a5fa)',
    borderRadius: '2px'
  }
}));

const CyberSecuritySolutions = () => {
  const bannerPara =
    "Protect your most valuable data and assets with National IT Services' robust cybersecurity solutions. Leverage leading platforms like Trend Micro, ESET, eScan, Fortinet, and more to implement layered defense across endpoints, networks, email, and cloud. Our integrated tools provide advanced threat protection, event correlation, compliance reporting, and real-time monitoring - securing your infrastructure at every level. Managed detection and response services add expert guidance and rapid response. With National IT Services, gain the technology and support to confidently deter attacks, simplify compliance, and enable cyber resilience. Defend against evolving threats with proactive and proven cybersecurity. The future of risk mitigation starts here.";
  const bannerHeading =
    "Secure Your Digital Ecosystem with Robust Cybersecurity";
  const Services = AllServices.filter(service => service.category === "Cyber");

  return (
    <>
      <AllCategoriesBanner 
        bannerPara={bannerPara} 
        bannerHeading={bannerHeading} 
      />
      <Container>
        <TitleSection>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: '#1e40af',
              mb: 1,
              fontSize: { xs: '1.75rem', md: '2rem' }
            }}
          >
            Cyber Security Solutions
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#64748b',
              maxWidth: '600px',
              margin: '0 auto',
              fontSize: '1.1rem'
            }}
          >
            Discover a wide range of services tailored to meet your needs.
          </Typography>
        </TitleSection>
        <AllCategoriesSingleGrid Services={Services} />
      </Container>
    </>
  );
};

export default CyberSecuritySolutions;
