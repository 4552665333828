import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Box,
  Paper,
  Chip,
  Avatar,
  Divider,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { alpha, styled } from "@mui/material/styles";
import blogsData from "../Components/HomePageBlog/blog";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { categoryColors } from "../Components/HomePageBlog/blog";

// Styled components
const HeroSection = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "75vh",
  width: "100%",
  marginTop: "60px",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(
      to bottom,
      rgba(0,0,0,0.4) 0%,
      rgba(0,0,0,0.6) 50%,
      rgba(0,0,0,0.8) 100%
    )`,
    zIndex: 1,
  },
}));

const StyledTag = styled(Chip)(({ theme }) => ({
  borderRadius: "6px",
  height: "28px",
  fontSize: "0.85rem",
  fontWeight: 500,
  "&:hover": {
    transform: "translateY(-1px)",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
  transition: "all 0.2s ease",
}));

const StyledMoreArticles = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 800,
  color: "#1a237e",
  position: "relative",
  display: "inline-block",
  marginBottom: theme.spacing(4),
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: -8,
    left: 0,
    width: "60%",
    height: 4,
    background: "linear-gradient(90deg, #1a237e 0%, #2196F3 100%)",
    borderRadius: 2,
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  background:
    "linear-gradient(to right, rgba(0,0,0,0.08) 0%, rgba(0,0,0,0.15) 50%, rgba(0,0,0,0.08) 100%)",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: "20px",
  overflow: "hidden",
  transition: "all 0.3s ease",
  background: theme.palette.background.paper,
  border: "1px solid",
  borderColor: theme.palette.divider,
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 20px 40px rgba(0,0,0,0.08)",
    "& .card-image": {
      transform: "scale(1.05)",
    },
    "& .read-more-icon": {
      transform: "translateX(4px)",
    },
  },
}));

const RelatedArticleCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  borderRadius: 16,
  overflow: "hidden",
  transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  textDecoration: "none",
  background: theme.palette.background.paper,
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 16px 32px rgba(0,0,0,0.1)",
    "& .article-image": {
      transform: "scale(1.08)",
    },
    "& .read-more": {
      color: theme.palette.primary.main,
      gap: "12px",
    },
  },
}));

const ViewAllButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: "16px 32px",
  borderRadius: "12px",
  fontSize: "1rem",
  fontWeight: 600,
  textTransform: "none",
  position: "relative",
  overflow: "hidden",
  background: "linear-gradient(45deg, #1a237e, #1976d2)",
  color: "white",
  border: "none",
  transition: "all 0.3s ease",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(45deg, #1976d2, #1a237e)",
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 8px 24px rgba(25, 118, 210, 0.3)",
    "&::before": {
      opacity: 1,
    },
    "& .arrow-icon": {
      transform: "translateX(4px)",
    },
  },
  "&:active": {
    transform: "translateY(1px)",
  },
}));

const BlogDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const blogId = parseInt(id);
  const blog = blogsData.find((blog) => blog.id === blogId);
  const otherBlogs = blogsData.filter((b) => b.id !== blogId).slice(0, 3); // Get only 3 blogs

  if (!blog) {
    return (
      <Typography variant="h6" align="center" color="error">
        Blog not found
      </Typography>
    );
  }

  return (
    <>
      <HeroSection>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${blog.imageSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 0,
          }}
        />

        <Container
          maxWidth="lg"
          sx={{
            height: "100%",
            position: "relative",
            zIndex: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            pb: 8,
            pt: { xs: 12, md: 16 },
          }}
        >
          {/* Author and Metadata */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              mb: 3,
            }}
          >
            <Avatar
              src={blog.author.avatar}
              alt={blog.author.name}
              sx={{
                width: 48,
                height: 48,
                border: "2px solid white",
              }}
            />
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  fontWeight: 600,
                  textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
                }}
              >
                {blog.author.name}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "rgba(255,255,255,0.8)",
                  fontWeight: 500,
                }}
              >
                {blog.author.role}
              </Typography>
            </Box>
          </Box>

          {/* Title */}
          <Typography
            variant="h1"
            sx={{
              fontWeight: 800,
              fontSize: { xs: "2.5rem", md: "4rem" },
              lineHeight: 1.2,
              color: "white",
              textShadow: "2px 2px 8px rgba(0,0,0,0.5)",
              mb: 3,
              maxWidth: "80%",
            }}
          >
            {blog.title}
          </Typography>

          {/* Metadata Row */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              alignItems: "center",
              mb: 3,
            }}
          >
            <Chip
              label={blog.category}
              sx={{
                bgcolor: alpha(categoryColors[blog.category].light, 0.9),
                color: categoryColors[blog.category].main,
                fontWeight: 600,
                backdropFilter: "blur(4px)",
                fontSize: "0.9rem",
                height: 32,
                "& .MuiChip-label": {
                  px: 2,
                },
              }}
            />

            <Box
              sx={{
                display: "flex",
                gap: 3,
                color: "rgba(255,255,255,0.9)",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                }}
              >
                <CalendarTodayIcon sx={{ fontSize: 18 }} />
                {blog.date}
              </Typography>

              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                }}
              >
                <AccessTimeIcon sx={{ fontSize: 18 }} />
                {blog.readingTime}
              </Typography>

              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                }}
              >
                <VisibilityIcon sx={{ fontSize: 18 }} />
                {blog.views} views
              </Typography>
            </Box>
          </Box>

          {/* Tags */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {blog.tags.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                size="small"
                sx={{
                  bgcolor: "rgba(255,255,255,0.15)",
                  color: "white",
                  backdropFilter: "blur(4px)",
                  "&:hover": {
                    bgcolor: "rgba(255,255,255,0.25)",
                  },
                  textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                  height: 28,
                }}
              />
            ))}
          </Box>
        </Container>
      </HeroSection>

      {/* Main Content */}
      <Container
        maxWidth="lg"
        sx={{ mt: -6, mb: 8, position: "relative", zIndex: 3 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Paper
              elevation={2}
              sx={{
                p: { xs: 3, md: 5 },
                borderRadius: 3,
                bgcolor: "#fff",
                boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
              }}
            >
              {/* Tags */}
              <Box sx={{ mb: 4, display: "flex", gap: 1, flexWrap: "wrap" }}>
                {blog.tags.map((tag) => (
                  <StyledTag
                    key={tag}
                    label={tag}
                    size="small"
                    icon={<LocalOfferIcon sx={{ fontSize: 16 }} />}
                    sx={{
                      bgcolor: "grey.100",
                      "&:hover": { bgcolor: "grey.200" },
                    }}
                  />
                ))}
              </Box>

              {/* Content */}
              <Typography
                variant="h4"
                sx={{
                  mb: 4,
                  fontWeight: 700,
                  color: "text.primary",
                  lineHeight: 1.4,
                }}
              >
                {blog.intro}
              </Typography>

              <Box
                sx={{
                  typography: "body1",
                  lineHeight: 1.8,
                  color: "text.secondary",
                  "& p": { mb: 3 },
                  "& h2": {
                    color: "text.primary",
                    fontWeight: 700,
                    mt: 5,
                    mb: 3,
                  },
                  "& ul, & ol": {
                    pl: 3,
                    mb: 3,
                  },
                }}
              >
                <ReactMarkdown>{blog.content}</ReactMarkdown>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              sx={{
                position: "sticky",
                top: 24,
                p: 3,
                borderRadius: 3,
                bgcolor: "background.paper",
                boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
              }}
            >
              <StyledMoreArticles>More Articles</StyledMoreArticles>

              {otherBlogs.map((article) => (
                <RelatedArticleCard
                  key={article.id}
                  component={Link}
                  to={`/blog/${article.id}`}
                >
                  <Box
                    sx={{ position: "relative", pt: "60%", overflow: "hidden" }}
                  >
                    {/* Gradient Overlay */}
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background:
                          "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%)",
                        zIndex: 1,
                      }}
                    />

                    <CardMedia
                      component="img"
                      image={article.imageSrc}
                      alt={article.title}
                      className="article-image"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        transition:
                          "transform 0.6s cubic-bezier(0.4, 0, 0.2, 1)",
                      }}
                    />

                    {/* Category Chip */}
                    <Chip
                      label={article.category}
                      size="small"
                      sx={{
                        position: "absolute",
                        top: 16,
                        left: 16,
                        zIndex: 2,
                        bgcolor: alpha(
                          categoryColors[article.category].light,
                          0.95
                        ),
                        color: categoryColors[article.category].main,
                        fontWeight: 600,
                        backdropFilter: "blur(4px)",
                        fontSize: "0.75rem",
                        height: "24px",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          transform: "translateY(-2px)",
                          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        },
                      }}
                    />
                  </Box>

                  <CardContent sx={{ p: 3 }}>
                    {/* Title */}
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                        mb: 2,
                        color: "text.primary",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        lineHeight: 1.4,
                        fontSize: "1.1rem",
                      }}
                    >
                      {article.title}
                    </Typography>

                    {/* Metadata */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 2,
                        pb: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                          color: "text.secondary",
                          fontWeight: 500,
                        }}
                      >
                        <AccessTimeIcon sx={{ fontSize: 14 }} />
                        {article.readingTime}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                          color: "text.secondary",
                          fontWeight: 500,
                        }}
                      >
                        <VisibilityIcon sx={{ fontSize: 14 }} />
                        {article.views} views
                      </Typography>
                    </Box>

                    {/* Description */}
                    <Typography
                      variant="body2"
                      sx={{
                        color: "text.secondary",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        mb: 2,
                        lineHeight: 1.6,
                        height: "3.2em",
                      }}
                    >
                      {article.intro}
                    </Typography>

                    {/* Read More Link */}
                    <Box
                      className="read-more"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "text.secondary",
                        gap: 0.5,
                        fontWeight: 600,
                        fontSize: "0.875rem",
                        transition: "all 0.3s ease",
                      }}
                    >
                      Read More
                      <ArrowForwardIcon
                        sx={{
                          fontSize: 18,
                          transition: "transform 0.3s ease",
                          transform: "translateX(0)",
                          ".RelatedArticleCard:hover &": {
                            transform: "translateX(4px)",
                          },
                        }}
                      />
                    </Box>
                  </CardContent>
                </RelatedArticleCard>
              ))}

              <Box sx={{ mt: 4, px: 2 }}>
                <ViewAllButton
                  component={Link}
                  to="/blogs"
                  endIcon={
                    <ArrowForwardIcon
                      className="arrow-icon"
                      sx={{
                        transition: "transform 0.3s ease",
                        fontSize: "24px",
                      }}
                    />
                  }
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    View All Articles
                  </Box>
                </ViewAllButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default BlogDetails;
