import React, { useEffect, useRef } from "react";
import { Box, Container, Typography, Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { styled, keyframes } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VerifiedIcon from "@mui/icons-material/Verified";
import SecurityIcon from "@mui/icons-material/Security";
import SpeedIcon from "@mui/icons-material/Speed";
import StarIcon from "@mui/icons-material/Star";

const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const pulse = keyframes`
  0% { opacity: 0.4; transform: scale(1); }
  50% { opacity: 0.7; transform: scale(1.05); }
  100% { opacity: 0.4; transform: scale(1); }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const dash = keyframes`
  to { stroke-dashoffset: -1000; }
`;

const HeroSection = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  paddingTop: '40px',
  background: '#000B1C',
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at 50% 50%, rgba(25, 118, 210, 0.1), transparent 70%)',
  }
}));

const ParticleCanvas = styled('canvas')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none'
});

const TechCircle = styled(Box)({
  position: 'absolute',
  borderRadius: '50%',
  border: '1px solid rgba(25, 118, 210, 0.2)',
  animation: `${float} 6s infinite ease-in-out`,
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '10px',
    height: '10px',
    background: '#1976d2',
    borderRadius: '50%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 0 20px #1976d2',
    animation: `${pulse} 3s infinite`
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    borderRadius: '50%',
    border: '1px solid rgba(25, 118, 210, 0.3)',
    animation: `${rotate} 10s linear infinite`
  }
});

const HexagonGrid = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: `
    linear-gradient(60deg, rgba(25, 118, 210, 0.1) 25%, transparent 25.5%, transparent 75%, rgba(25, 118, 210, 0.1) 75%),
    linear-gradient(120deg, rgba(25, 118, 210, 0.1) 25%, transparent 25.5%, transparent 75%, rgba(25, 118, 210, 0.1) 75%)
  `,
  backgroundSize: '60px 104px',
  opacity: 0.3,
});

const CircuitLines = styled('svg')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  '& path': {
    stroke: 'rgba(25, 118, 210, 0.2)',
    strokeWidth: '1',
    fill: 'none',
    strokeDasharray: '5,5',
    animation: `${dash} 20s linear infinite`,
  }
});

const TrustBadge = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  background: "rgba(0, 89, 178, 0.1)",
  backdropFilter: "blur(10px)",
  borderRadius: "100px",
  padding: "8px 16px",
  color: "#fff",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  marginBottom: theme.spacing(4),
  "& .star-icon": {
    color: "#FFD700",
    marginRight: theme.spacing(1),
  },
}));

const FeatureChip = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  background: "rgba(255, 255, 255, 0.05)",
  backdropFilter: "blur(8px)",
  borderRadius: "100px",
  padding: "8px 16px",
  marginLeft:'0px !important',
  color: "#fff",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  marginRight: theme.spacing(2),
  "& .icon": {
    marginRight: theme.spacing(1),
    fontSize: "1.2rem",
  },
}));

const ContentWrapper = styled(Container)(({ theme }) => ({
  position: "relative",
  zIndex: 2,
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(12),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(12),
  },
}));

const HeroTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.8rem',
  fontWeight: 800,
  background: 'linear-gradient(135deg, #FFFFFF 0%, #B6CCF5 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(4),
  lineHeight: 1.2,
  position: 'relative',
  paddingBottom: '24px',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '3px',
    background: 'linear-gradient(90deg, #3b82f6, #60a5fa)',
    borderRadius: '2px'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2.8rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.2rem',
  }
}));

const HeroSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  lineHeight: 1.8,
  color: "#94A3B8",
  marginBottom: theme.spacing(6),
  maxWidth: "800px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.1rem",
  },
}));

const ActionButton = styled(Button)(({ primary }) => ({
  width: 'auto',
  padding: '16px 32px',
  borderRadius: '12px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 1,
  ...(primary ? {
    background: 'linear-gradient(45deg, #1a237e, #1976d2)',
    color: 'white',
    border: 'none',
    transition: 'all 0.3s ease',
    '& .button-content': {
      position: 'relative',
      zIndex: 2,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(45deg, #1976d2, #1a237e)',
      opacity: 0,
      transition: 'opacity 0.3s ease',
      zIndex: 1,
    },
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 8px 24px rgba(25, 118, 210, 0.3)',
      '&::before': {
        opacity: 1,
      },
      '& .arrow-icon': {
        transform: 'translateX(4px)',
      },
    },
    '&:active': {
      transform: 'translateY(1px)',
    }
  } : {
    background: 'transparent',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    color: '#fff',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
      transform: 'translateY(-2px)',
      boxShadow: '0 8px 24px rgba(255, 255, 255, 0.1)',
    },
    '&:active': {
      transform: 'translateY(1px)',
    }
  })
}));

const HeroBanner = () => {
  const canvasRef = useRef(null);
  const mouseRef = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let particles = [];

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    // Enhanced Particle class
    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 2 + 1;
        this.baseX = this.x;
        this.baseY = this.y;
        this.density = Math.random() * 30 + 1;
        this.speedX = Math.random() * 2 - 1;
        this.speedY = Math.random() * 2 - 1;
        this.color = `hsla(${210 + Math.random() * 30}, 100%, 70%, ${Math.random() * 0.3 + 0.2})`;
      }

      update() {
        // Mouse interaction
        const dx = mouseRef.current.x - this.x;
        const dy = mouseRef.current.y - this.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const forceDirectionX = dx / distance;
        const forceDirectionY = dy / distance;
        const maxDistance = 100;
        const force = (maxDistance - distance) / maxDistance;
        
        if (distance < maxDistance) {
          this.x += forceDirectionX * force * this.density;
          this.y += forceDirectionY * force * this.density;
        }

        // Normal movement
        this.x += this.speedX;
        this.y += this.speedY;

        // Boundary check
        if (this.x > canvas.width) this.x = 0;
        if (this.x < 0) this.x = canvas.width;
        if (this.y > canvas.height) this.y = 0;
        if (this.y < 0) this.y = canvas.height;
      }

      draw() {
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    const init = () => {
      particles = [];
      for (let i = 0; i < 150; i++) {
        particles.push(new Particle());
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      particles.forEach(particle => {
        particle.update();
        particle.draw();
      });

      // Enhanced connecting lines
      particles.forEach(a => {
        particles.forEach(b => {
          const dx = a.x - b.x;
          const dy = a.y - b.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 100) {
            ctx.strokeStyle = `rgba(25, 118, 210, ${0.15 * (1 - distance/100)})`;
            ctx.lineWidth = 0.5;
            ctx.beginPath();
            ctx.moveTo(a.x, a.y);
            ctx.lineTo(b.x, b.y);
            ctx.stroke();
          }
        });
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    const handleMouseMove = (event) => {
      mouseRef.current = {
        x: event.clientX,
        y: event.clientY
      };
    };

    window.addEventListener('mousemove', handleMouseMove);
    init();
    animate();

    return () => {
      window.removeEventListener('resize', setCanvasSize);
      window.removeEventListener('mousemove', handleMouseMove);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <HeroSection>
      <HexagonGrid />
      <CircuitLines>
        {/* Add circuit-like paths */}
        <path d="M0,50 Q200,100 400,50 T800,50" />
        <path d="M0,150 Q200,200 400,150 T800,150" />
      </CircuitLines>
      <ParticleCanvas ref={canvasRef} />
      
      <TechCircle sx={{ width: '300px', height: '300px', top: '20%', right: '10%' }} />
      <TechCircle sx={{ width: '200px', height: '200px', bottom: '15%', left: '5%', animationDelay: '-2s' }} />
      <TechCircle sx={{ width: '150px', height: '150px', top: '30%', left: '15%', animationDelay: '-4s' }} />

      <ContentWrapper maxWidth="xl">
        <TrustBadge>
          <StarIcon className="star-icon" />
          Trusted by 500+ Businesses
        </TrustBadge>

        <HeroTitle>
          NITS Helps You to  Buy Right Software{' '}
          <Box component="span" sx={{ display: { xs: 'inline', md: 'block' } }}>
            for Your Business Growth
          </Box>
        </HeroTitle>

        <Stack 
          direction="row" 
          spacing={2} 
          sx={{ mb: 4, flexWrap: 'wrap', gap: 1 }}
        >
          <FeatureChip>
            <VerifiedIcon className="icon" />
             Certified Partner
          </FeatureChip>
          <FeatureChip>
            <SecurityIcon className="icon" />
            Enterprise Security
          </FeatureChip>
          <FeatureChip>
            <SpeedIcon className="icon" />
            24/7 Support
          </FeatureChip>
        </Stack>

        <HeroSubtitle>
          Enterprise Grade Information & Communications Solutions. Trusted
          Partner for Telecom, Security, Backup, CRM, Cloud Hosting & GST
          Compliance solutions.
        </HeroSubtitle>

        <Stack 
          direction={{ xs: 'column', sm: 'row' }} 
          spacing={2}
        >
          <ActionButton 
            primary 
            component={Link} 
            to="/get-in-touch"
          >
            <Box className="button-content" sx={{ display: 'flex', alignItems: 'center' }}>
              Do you Need Help?
              <ArrowForwardIcon 
                className="arrow-icon" 
                sx={{ 
                  transition: 'transform 0.3s ease',
                  ml: 1 
                }} 
              />
            </Box>
          </ActionButton>
          <ActionButton component={Link} to="/all-services">
            VIEW SOLUTIONS
          </ActionButton>
        </Stack>
      </ContentWrapper>
    </HeroSection>
  );
};

export default HeroBanner;
