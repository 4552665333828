import React, { useState, useEffect } from "react";
import "../assets/style/contact.css";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { styled, keyframes } from "@mui/material/styles";
import { Box, Typography, TextField, Button, Grid, Container } from "@mui/material";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const TitleSection = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(6),
  animation: `${fadeIn} 1s ease-out`,
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: -16,
    left: "50%",
    transform: "translateX(-50%)",
    width: "60px",
    height: "3px",
    background: "linear-gradient(90deg, #3b82f6, #60a5fa)",
    borderRadius: "2px",
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: 12,
  padding: '12px 32px',
  fontSize: '1rem',
  textTransform: 'none',
  color: '#ffffff',
  fontWeight: 600,
  background: 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)',
  boxShadow: '0 4px 14px rgba(59, 130, 246, 0.25)',
  transition: 'all 0.3s ease',
  '&:hover': {
    color: '#ffffff',
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 20px rgba(59, 130, 246, 0.35)'
  },
  '&:disabled': {
    color: 'rgba(255, 255, 255, 0.8)',
  }
}));

const GetInTouch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleCloseSnackBar = (event, reason) => {
    setOpen(false);
  };
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone Number is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    }
    if (!formData.message) {
      newErrors.message = "Message  is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const apiUrl = "http://localhost:8080/functions/api/contact";
    if (validateForm()) {
      setLoading(true);
      fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setOpen(true);
            setFormData({
              name: "",
              phone: "",
              email: "",
              message: "",
            });
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
          return response.json();
        })
        .then((data) => {
          console.log("API response:", data);
        })
        .catch((error) => {
          console.error("API error:", error);
        });
    }
  };
  return (
    <>
      <div className="container-fluid" style={{ padding: 0 }}>
        <div className="row">
          <div
            className="col-lg-12"
            style={{ position: "relative", marginTop: 60 }}
          >
            <div
              style={{
                position: "absolute",
                bottom: 20,
                right: 20,
                zIndex: 1,
                background: "white",
                padding: "20px 25px",
                borderRadius: "12px",
                boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                maxWidth: "300px",
              }}
            >
              <h5
                style={{
                  color: "#1a237e",
                  marginBottom: "15px",
                  fontWeight: 600,
                }}
              >
                Visit Us
              </h5>
              <p style={{ margin: 0, fontSize: "14px", lineHeight: "1.6" }}>
                National IT Services,
                <br />
                718, 7th Floor, Vipul Plaza,
                <br />
                Sector 81, Faridabad, Haryana 121002
              </p>
            </div>

            <iframe
              title="Google Map"
              className="gmap_iframe"
              width="100%"
              height="450"
              style={{
                border: 0,
                filter: "grayscale(20%)",
              }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.9135262268446!2d77.344221375492!3d28.39167967579718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdc36824f338d%3A0x86ae179c0010c4e7!2sNational%20IT%20Services!5e0!3m2!1sen!2sin!4v1698044381151!5m2!1sen!2sin"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="container adsbfrmcon" style={{ padding: "0px" }}>
        <div className="row">
          <div className="col-lg-12" style={{ padding: "0px" }}>
            <TitleSection>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 700,
                  color: "#1e40af",
                  mb: 1,
                  fontSize: { xs: "1.75rem", md: "2rem" },
                }}
              >
                Get In Touch
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#64748b",
                  maxWidth: "600px",
                  margin: "0 auto",
                  fontSize: "1.1rem",
                }}
              >
                Contact us today for exceptional service. We're ready to assist
                you promptly with tailored solutions, ensuring an outstanding experience unlike any other.
              </Typography>
            </TitleSection>
          </div>
        </div>
        <br />

        <br />
        <Container 
          maxWidth="xl" 
          sx={{ 
            px: { xs: 2, sm: 3, md: 4 },
            py: { xs: 2, md: 4 }
          }}
        >
          <Grid container spacing={{ xs: 2, md: 4 }}>
            {/* Form Section */}
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  background: 'white',
                  borderRadius: { xs: '16px', md: '24px' },
                  boxShadow: '0 8px 32px rgba(0,0,0,0.06)',
                  padding: { xs: '20px', sm: '24px', md: '40px' },
                  height: '100%',
                  width: '100%',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 12px 40px rgba(0,0,0,0.08)',
                    transform: { xs: 'none', md: 'translateY(-4px)' },
                  },
                }}
              >
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    {/* Name & Phone Fields Row */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Name"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: { xs: '12px', md: '16px' },
                            backgroundColor: '#f8fafc',
                            height: { xs: '48px', md: '56px' },
                            '&:hover': {
                              backgroundColor: '#f1f5f9',
                            },
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: '#3b82f6',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#3b82f6',
                            },
                          },
                          '& .MuiInputLabel-root': {
                            fontSize: { xs: '0.875rem', md: '1rem' },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Phone"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        error={Boolean(errors.phone)}
                        helperText={errors.phone}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: { xs: '12px', md: '16px' },
                            backgroundColor: '#f8fafc',
                            height: { xs: '48px', md: '56px' },
                            '&:hover': {
                              backgroundColor: '#f1f5f9',
                            },
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: '#3b82f6',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#3b82f6',
                            },
                          },
                          '& .MuiInputLabel-root': {
                            fontSize: { xs: '0.875rem', md: '1rem' },
                          },
                        }}
                      />
                    </Grid>

                    {/* Email Field */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        id="email"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: { xs: '12px', md: '16px' },
                            backgroundColor: '#f8fafc',
                            height: { xs: '48px', md: '56px' },
                            '&:hover': {
                              backgroundColor: '#f1f5f9',
                            },
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: '#3b82f6',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#3b82f6',
                            },
                          },
                          '& .MuiInputLabel-root': {
                            fontSize: { xs: '0.875rem', md: '1rem' },
                          },
                        }}
                      />
                    </Grid>

                    {/* Message Field */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={{ xs: 3, md: 4 }}
                        label="Message"
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        error={Boolean(errors.message)}
                        helperText={errors.message}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: { xs: '12px', md: '16px' },
                            backgroundColor: '#f8fafc',
                            '&:hover': {
                              backgroundColor: '#f1f5f9',
                            },
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: '#3b82f6',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#3b82f6',
                            },
                          },
                          '& .MuiInputLabel-root': {
                            fontSize: { xs: '0.875rem', md: '1rem' },
                          },
                        }}
                      />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12}>
                      <SubmitButton
                        type="submit"
                        fullWidth
                        disabled={loading}
                        sx={{
                          mt: { xs: 1, md: 2 },
                          height: { xs: '48px', md: '54px' },
                          fontSize: { xs: '0.9rem', md: '1rem' },
                        }}
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Send Message"
                        )}
                      </SubmitButton>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  height: { xs: '250px', sm: '300px', md: '100%' },
                  minHeight: { xs: 'auto', md: '100%' },
                  width: '100%',
                  borderRadius: { xs: '16px', md: '24px' },
                  overflow: 'hidden',
                  boxShadow: '0 8px 32px rgba(0,0,0,0.06)',
                  position: 'relative',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 12px 40px rgba(0,0,0,0.08)',
                    transform: { xs: 'none', md: 'translateY(-4px)' },
                    '& img': {
                      transform: 'scale(1.05)',
                    },
                  },
                }}
              >
                <img
                  src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?auto=format&fit=crop&q=80&w=2940"
                  alt="contact us"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'transform 0.5s ease',
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: 'linear-gradient(to top, rgba(0,0,0,0.7), transparent)',
                    color: 'white',
                    padding: { xs: '24px 20px 20px', md: '40px 24px 24px' },
                  }}
                >
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontWeight: 600, 
                      mb: 1,
                      fontSize: { xs: '1.1rem', md: '1.25rem' }
                    }}
                  >
                    Let's Connect
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      opacity: 0.9,
                      fontSize: { xs: '0.875rem', md: '1rem' }
                    }}
                  >
                    We're here to help you grow your business
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          style={{ zIndex: "9999999999999999" }}
          open={open}
          autoHideDuration={2000}
          onClose={handleCloseSnackBar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MuiAlert
            style={{
              backgroundColor: "green",
              color: "white",
            }}
            onClose={handleCloseSnackBar}
            lg={{ width: "100%" }}
            severity="success"
          >
            Form Submitted Successfully
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default GetInTouch;
