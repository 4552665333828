import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const BannerWrapper = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #1e40af 0%, #3b82f6 100%)',
  position: 'relative',
  overflow: 'hidden',
  padding: '80px 0',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'url("/path-to-pattern.svg")', 
    opacity: 0.1,
    zIndex: 1,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '120px',
    background: 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
    zIndex: 2,
  }
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 3,
  animation: `${fadeIn} 1s ease-out`,
  maxWidth: '1000px',
  margin: '0 auto',
  textAlign: 'center',
  padding: '0 20px',
}));

const BannerTitle = styled(Typography)(({ theme }) => ({
  color: '#ffffff',
  fontSize: '2rem',
  fontWeight: 700,
  marginBottom: '24px',
  marginTop: '68px',
  lineHeight: 1.2,
  textShadow: '0 2px 4px rgba(0,0,0,0.1)',
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.75rem',
  },
}));

const BannerDescription = styled(Typography)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.9)',
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: 1.2,
  paddingBottom: '20px',
  maxWidth: '1200px',
  margin: '0 auto',
  textShadow: '0 1px 2px rgba(0,0,0,0.1)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

const AllCategoriesBanner = ({ bannerPara, bannerHeading }) => {
  return (
    <BannerWrapper>
      <Container maxWidth="xl">
        <ContentWrapper>
          <BannerTitle variant="h1">
            {bannerHeading}
          </BannerTitle>
          <BannerDescription>
            {bannerPara}
          </BannerDescription>
        </ContentWrapper>
      </Container>
    </BannerWrapper>
  );
};

export default AllCategoriesBanner;
