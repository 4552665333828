import React, { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import products from "./LatestProducts";
import ProductFormPopUp from "../ProductFormPopUp/ProductFormPopUp";
import { styled, keyframes } from "@mui/material/styles";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;
const StyledSwiper = styled(Swiper)({
  padding: "20px 0 40px 0",
  "& .swiper-button-next, & .swiper-button-prev": {
    width: "40px",
    height: "40px",
    background: "#fff",
    borderRadius: "50%",
    boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
    "&::after": {
      fontSize: "18px",
      color: "#666",
      fontWeight: "bold",
    },
    "&:hover": {
      background: "#f8f8f8",
      boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
    },
  },
  "& .swiper-button-prev": {
    left: "10px",
  },
  "& .swiper-button-next": {
    right: "10px",
  },
  "& .swiper-button-disabled": {
    opacity: 0,
    cursor: "not-allowed",
  },
});

const ProductCard = styled(Box)({
  background: "white",
  borderRadius: "16px",
  border: "none",
  padding: "24px",
  height: "400px",
  width: "100%",
  minWidth: "300px",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  margin: "8px 8px 32px 8px",
  transition: "all 0.3s ease",
  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.03)",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.08)",
  },
});

const ImageContainer = styled(Box)({
  background: "#f8fafb",
  borderRadius: "12px",
  padding: "20px",
  marginBottom: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "120px",
  "& img": {
    maxWidth: "80%",
    maxHeight: "80%",
    objectFit: "contain",
    transition: "transform 0.3s ease",
  },
  "&:hover img": {
    transform: "scale(1.05)",
  },
});

const ContentContainer = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "calc(100% - 180px)",
});

const Badge = styled(Box)({
  position: "absolute",
  top: "24px",
  left: "24px",
  background: "linear-gradient(135deg, #FF9800, #FF5722)",
  color: "white",
  padding: "6px 16px",
  borderRadius: "30px",
  fontSize: "13px",
  fontWeight: "600",
  letterSpacing: "0.3px",
  boxShadow: "0 4px 12px rgba(255, 152, 0, 0.3)",
});

const GetDemoButton = styled("button")({
  width: "100%",
  padding: "12px",
  background: "transparent",
  border: "2px solid #FF9800",
  borderRadius: "30px",
  color: "#FF9800",
  fontSize: "14px",
  fontWeight: "600",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    background: "linear-gradient(135deg, #FF9800, #FF5722)",
    borderColor: "transparent",
    color: "white",
    transform: "translateY(-2px)",
    boxShadow: "0 4px 12px rgba(255, 152, 0, 0.3)",
  },
});

const FeatureBadge = styled(Box)({
  background: "linear-gradient(135deg, #E3F2FD, #BBDEFB)",
  color: "#1976D2",
  padding: "6px 12px",
  borderRadius: "8px",
  fontSize: "12px",
  fontWeight: "600",
  display: "inline-flex",
  alignItems: "center",
  gap: "4px",
  letterSpacing: "0.2px",
});

const TitleSection = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(6),
  animation: `${fadeIn} 1s ease-out`,
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: -16,
    left: "50%",
    transform: "translateX(-50%)",
    width: "60px",
    height: "3px",
    background: "linear-gradient(90deg, #3b82f6, #60a5fa)",
    borderRadius: "2px",
  },
}));

const LatestProduct = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const duplicatedProducts = [...products, ...products, ...products];

  return (
    <Box
      sx={{
        py: 6,
        px: {
          xs: 0,
          md: 8,
        },
        background: "#f8f9fa",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="xl" sx={{ maxWidth: "1800px !important" }}>
        <Box sx={{ mb: 3, textAlign: "center" }}>
          <TitleSection>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                color: "#1e40af",
                mb: 1,
                fontSize: { xs: "1.75rem", md: "2rem" },
              }}
            >
              Latest Products
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#64748b",
                maxWidth: "600px",
                margin: "0 auto",
                fontSize: "1.1rem",
              }}
            >
              Explore our most popular enterprise solutions
            </Typography>
          </TitleSection>
        </Box>

        <StyledSwiper
          modules={[Autoplay, Navigation]}
          spaceBetween={20}
          navigation={true}
          loop={true}
          speed={500}
          slidesPerGroup={1}
          slidesPerView={4}
          allowTouchMove={true}
          observer={true}
          observeParents={true}
          watchSlidesProgress={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            600: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            960: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {duplicatedProducts.map((product, index) => (
            <SwiperSlide key={`product-${index}`}>
              <ProductCard
                sx={{
                  width: {
                    xs: "100%",
                    lg: "calc(25% - 20px)",
                  },
                }}
              >
                <Badge>Best Seller</Badge>

                <ImageContainer>
                  <img src={product.imageSrc} alt={product.productName} />
                </ImageContainer>

                <ContentContainer>
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#1A237E",
                        mb: 1,
                        lineHeight: 1.2,
                        height: "40px",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {product.productName}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#64748B",
                        fontSize: "14px",
                        fontWeight: 500,
                        mb: 2,
                      }}
                    >
                      By {product.productCompany}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mb: 2,
                      }}
                    >
                      <Box sx={{ display: "flex", gap: 0.5 }}>
                        {[...Array(5)].map((_, i) => (
                          <Box
                            key={i}
                            component="span"
                            sx={{
                              color: i < product.rating ? "#FFA000" : "#E0E0E0",
                              fontSize: "16px",
                            }}
                          >
                            ★
                          </Box>
                        ))}
                      </Box>
                      <Typography
                        sx={{
                          color: "#64748B",
                          fontSize: "13px",
                        }}
                      >
                        ({product.rating}.0)
                      </Typography>
                    </Box>

                    <FeatureBadge sx={{ mb: 2 }}>
                      <span>✨</span>
                      New Feature
                    </FeatureBadge>
                  </Box>

                  <Box>
                    <GetDemoButton onClick={() => setSelectedProduct(product)}>
                      Get Demo
                    </GetDemoButton>
                  </Box>
                </ContentContainer>
              </ProductCard>
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </Container>
      <ProductFormPopUp
        product={selectedProduct}
        onClose={() => setSelectedProduct(null)}
      />
    </Box>
  );
};

export default LatestProduct;
