import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export const popupConfig = {
  title: "Sign Documents Smarter, Not Harder",
  subtitle:
    "Get your documents signed in minutes, not days. Simple, secure, and smart.",
  features: [
    {
      id: 1,
      text: "Sign Documents Anywhere, Anytime",
      icon: "StarIcon",
    },
    {
      id: 2,
      text: "Bank-Level Security for Your Files",
      icon: "StarIcon",
    },
    {
      id: 3,
      text: "Track Who Signed and When",
      icon: "StarIcon",
    },
    {
      id: 4,
      text: "Works with Your Favorite Apps",
      icon: "StarIcon",
    },
    {
      id: 5,
      text: "Smart Document Organization",
      icon: "StarIcon",
    },
  ],
  form: {
    emailLabel: "Enter Your Email",
    submitButton: "Try It Free",
    successMessage: "Great! Check your email to get started.",
  },
  styles: {
    gradientColors: {
      start: "#FF5252",
      end: "#4C00FF",
    },
    backgroundImage:
      "https://pbs.twimg.com/media/GenDyNJXcAA_BQz?format=jpg&name=medium",
    dialogHeight: 500,
  },
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 16,
    maxWidth: 800,
    maxHeight: "80vh",
    margin: 0,
    boxShadow: "0 20px 80px -10px rgba(0,0,0,0.2)",
    transform: "perspective(1000px) rotateX(0deg)",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "perspective(1000px) rotateX(0.5deg) translateY(-5px)",
    },
  },
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
  background: `radial-gradient(100% 100% at 50% -20%, ${popupConfig.styles.gradientColors.start} 0%, ${popupConfig.styles.gradientColors.end} 100%)`,
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
  color: "transparent",
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  textShadow: "0 2px 4px rgba(0,0,0,0.1)",
  transform: "translateZ(20px)",
}));

const AdPopUp = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const apiUrl =
    "https://itforsmebackend.netlify.app/.netlify/functions/api/boost";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          setSnackbarOpen(true);
          setIsOpen(false);
          setFormData({
            email: "",
          });
          localStorage.setItem("popupCloseTime", new Date().getTime());
        } else {
          throw new Error("API request failed");
        }
      } catch (error) {
        console.error(error);
        setIsOpen(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  const onClose = () => {
    setIsOpen(false);
    localStorage.setItem("popupCloseTime", new Date().getTime());
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const popupCloseTime = localStorage.getItem("popupCloseTime");
    const currentTime = new Date().getTime();

    if (!popupCloseTime) {
      setTimeout(() => {
        setIsOpen(true);
      }, 1000);
    } else {
      const timeDifference = currentTime - parseInt(popupCloseTime);
      setIsOpen(timeDifference > 1 * 60 * 1000);
    }
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <>
      <StyledDialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent
          sx={{
            p: 0,
            overflow: "hidden",
            "&.MuiDialogContent-root": {
              padding: 0,
              overflow: "hidden",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "80vh",
              maxHeight: 600,
              position: "relative",
              perspective: "1000px",
              "@media (max-width: 600px)": {
                flexDirection: "column",
                height: "auto",
                maxHeight: "auto",
              },
            }}
          >
            <Box
              sx={{
                flex: 1,
                px: 4,
                py: 2,
                position: "relative",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "rgba(255,255,255,0.8)",
                  backdropFilter: "blur(10px)",
                  borderRadius: "16px",
                  zIndex: -1,
                },
              }}
            >
              <IconButton
                onClick={onClose}
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>

              <GradientTypography variant="h4">
                {popupConfig.title}
              </GradientTypography>

              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1,
                  fontSize: "0.9rem",
                  color: "text.secondary",
                  "@media (max-width: 600px)": {
                    fontSize: "0.75rem",
                    mb: 0.5,
                  },
                }}
              >
                {popupConfig.subtitle}
              </Typography>

              <List sx={{ mb: 4, position: "relative" }}>
                {popupConfig.features.map((feature, index) => (
                  <ListItem
                    key={feature.id}
                    sx={{
                      py: 0.5,
                      transform: `translateZ(${index * 2}px)`,
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        transform: `translateZ(${
                          index * 2 + 10
                        }px) translateX(10px)`,
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <StarIcon
                        sx={{
                          color: "primary.main",
                          filter: "drop-shadow(3px 3px 5px rgba(0,0,0,0.3))",
                          transform:
                            "perspective(100px) rotateX(10deg) rotateY(5deg)",
                          transition: "all 0.3s ease-in-out",
                          "&:hover": {
                            transform:
                              "perspective(100px) rotateX(0deg) rotateY(0deg) scale(1.2)",
                            filter: "drop-shadow(5px 5px 8px rgba(0,0,0,0.4))",
                            color: popupConfig.styles.gradientColors.start,
                          },
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={feature.text}
                      sx={{
                        "& .MuiTypography-root": {
                          fontWeight: 500,
                          transition: "all 0.2s ease-in-out",
                          "&:hover": {
                            textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
                          },
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>

              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  "@media (max-width: 600px)": {
                    marginBottom: "50px",
                  },
                }}
              >
                <TextField
                  fullWidth
                  id="email"
                  label={popupConfig.form.emailLabel}
                  variant="outlined"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  sx={{ mb: 3 }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="medium"
                  sx={{
                    py: 1,
                    background: `linear-gradient(45deg, ${popupConfig.styles.gradientColors.start} 30%, ${popupConfig.styles.gradientColors.end} 90%)`,
                    boxShadow: "0 8px 16px -4px rgba(0,0,0,0.2)",
                    "&:hover": {
                      background: `linear-gradient(45deg, ${popupConfig.styles.gradientColors.start}DD 30%, ${popupConfig.styles.gradientColors.end}DD 90%)`,
                    },
                    "@media (max-width: 600px)": {
                      py: 0.5,
                      fontSize: "0.65rem",
                      marginBottom: "30px",
                      height: "50px",
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    popupConfig.form.submitButton
                  )}
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                flex: 1,
                background: `url("${popupConfig.styles.backgroundImage}")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "linear-gradient(135deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.4) 100%)",
                  backdropFilter: "blur(0px)",
                },
                "@media (max-width: 600px)": {
                  display: "none",
                },
              }}
            />
          </Box>
        </DialogContent>
      </StyledDialog>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          style={{ zIndex: "9999999999999999" }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackBar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MuiAlert
            style={{
              backgroundColor: "green",
              color: "white",
            }}
            onClose={handleCloseSnackBar}
            lg={{ width: "100%" }}
            severity="success"
          >
            {popupConfig.form.successMessage}
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default AdPopUp;
