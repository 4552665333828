import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import AllCategoriesBanner from "../../Components/All Categories Components/All Categories Banner/AllCategoriesBanner";
import AllCategoriesSingleGrid from "../../Components/All Categories Components/All Categories Single Grid/AllCategoriesSingleGrid";
import AllServices from "../../Components/All Services Components/All Services Data/AllServicesData";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const TitleSection = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(6),
  animation: `${fadeIn} 1s ease-out`,
  position: 'relative',
  padding: "48px 0 20px",
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -16,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '3px',
    background: 'linear-gradient(90deg, #3b82f6, #60a5fa)',
    borderRadius: '2px'
  }
}));

const VoiceDataSolutions = () => {
  const bannerPara =
    "Enable seamless, secure communications across your organization with National IT Services' robust voice and data solutions. Bring your network infrastructure to the next level with high-speed leased lines, reliable PRI/SIP trunking, cloud telephony, VoIP minutes, and more. Our solutions ensure crystal-clear call quality, efficient routing, and scalable capacity to support your growth. With cutting-edge contact center platforms, toll-free services, MPLS, SD-WAN, and expert management, we empower enterprise-grade voice and data. Partner with National IT Services for the technology and support to elevate your capabilities. The future of seamless communication starts here.";
  const bannerHeading = "Power Your Business with Enterprise-Grade Voice & Data";
  const Services = AllServices.filter(service => service.category === "Voice");

  return (
    <>
      <AllCategoriesBanner 
        bannerPara={bannerPara} 
        bannerHeading={bannerHeading} 
      />
      <Container>
        <TitleSection>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: '#1e40af',
              mb: 1,
              fontSize: { xs: '1.75rem', md: '2rem' }
            }}
          >
            Voice & Data Solutions
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#64748b',
              maxWidth: '600px',
              margin: '0 auto',
              fontSize: '1.1rem'
            }}
          >
            Discover a wide range of services tailored to meet your needs.
          </Typography>
        </TitleSection>
        <AllCategoriesSingleGrid Services={Services} />
      </Container>
    </>
  );
};

export default VoiceDataSolutions;
